import React from 'react'

import {Banner} from "../components/sections/main/Banner";
// import {Soup} from "../components/sections/main/Soup";
import {About} from "../components/sections/main/About";
import {Hits} from "../components/sections/main/Hits";
import {Kimchi} from "../components/sections/main/Kimchi/Kimchi";
import {Restaurants} from "../components/sections/main/Restaurants";
import {Testimonials} from '../components/sections/main/Testimonials';
import {Questions} from '../components/sections/main/Questions';

import {AppContext} from "../store/context";
import {Statistics} from "../components/sections/main/Statistics/Statistics";
import {Team} from "../components/sections/main/Team/Team";

export default class Main extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <>
            <Banner/>
            {/* <Soup/> */}
            <Statistics />
            <About/>
            <Hits/>
            <Restaurants/>
            <Testimonials/>
            <Questions/>
        </>
    }
}

Main.contextType = AppContext;
