import React from 'react'
import {AppContext} from "../../store/context";
import {BannerSection} from "../../components/sections/catalog/PromoCategory";
import {ActionMainBlock} from "../../components/sections/catalog/Action";
import {ProductCard} from "../../components/sections/catalog/ProductCard";

import styles from './Main.module.css'
import {ResponsiveActionModalOnFuckingSinglePage} from "../../components/modals/Action";

export default class Main extends React.Component {
    constructor() {
        super()
        this.onSelect = this.onSelect.bind(this)
        this.onDismiss = this.onDismiss.bind(this)

        this.state = {
            action: null,
            modalOpen: false,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    onDismiss() {
        this.setState({modalOpen: false})
    }

    onSelect(action) {
        this.setState({modalOpen: true, action: action})
    }


    render() {
        return <div className={styles.wrapper}>
            <div className={styles.promo}>
                <div className={styles.sectionTitle}>
                    <div className={styles.sectionTitleText}>
                        обязательно попробуйте
                    </div>
                </div>
                <BannerSection/>
            </div>
            <ResponsiveActionModalOnFuckingSinglePage action={this.state.action}
                                                      isOpen={this.state.modalOpen}
                                                      onDismiss={this.onDismiss}/>
        </div>
    }
}

Main.contextType = AppContext;