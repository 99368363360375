import {useContext, useEffect} from "react";
import {useLocation, useRouteMatch} from "react-router-dom";
import ym from "react-yandex-metrika";

import {AppContext} from "./store/context";

export const usePageViews = () => {
    const {context} = useContext(AppContext)
    const location = useLocation();
    const route = useRouteMatch();

    useEffect(() => {
        ym('hit', location.pathname);

        // Do not use global seo for categories and products
        if (route.path !== "/delivery/:slug" &&
            route.path !== "/delivery/:slug/:product" &&
            route.path !== "/korean-market" &&
            route.path !== "/korean-market/:product"
        ) {
            context.processor.screen.seo(location.pathname).then(res => {
                context.common.dispatch({
                    type: 'setSeo',
                    payload: {
                        title: res.seo.title,
                        description: res.seo.description,
                        bottomText: res.seo.text
                    }
                })
            })
        }
    }, [location]);
};

export const addToCartGoal = () => {
    ym('reachGoal','addtocart')
}

export const applyPromocodeGoal = () => {
    ym('reachGoal','promocode')
}

export const checkoutGoal = () => {
    ym('reachGoal','checkout')
}