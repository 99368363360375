import {Base} from "./base.api";
import {API_HOST} from "../const";

const BASE_URL = `${API_HOST}/screen`;

export class Screen extends Base {
    init() {
        return this.fetchJSON(`${BASE_URL}/init`).then(response => {
            return response.data
        })
    }

    blog() {
        return this.fetchJSON(`${BASE_URL}/blog`).then(response => {
            return response.data
        })
    }

    operating() {
        return this.fetchJSON(`${BASE_URL}/operating`).then(response => {
            return response.data
        })
    }


    page(slug) {
        return this.fetchJSON(`${BASE_URL}/page?alias=${slug}`).then(response => {
            return response.data
        })
    }

    vacancies() {
        return this.fetchJSON(`${BASE_URL}/vacancies`).then(response => {
            return response.data
        })
    }

    actions() {
        return this.fetchJSON(`${BASE_URL}/actions`).then(response => {
            return response.data
        })
    }

    article(slug) {
        return this.fetchJSON(`${BASE_URL}/blog/${slug}`).then(response => {
            return response.data
        })
    }

    restaurant(slug) {
        return this.fetchJSON(`${BASE_URL}/restaurant/${slug}`).then(response => {
            return response.data
        })
    }

    order(hash) {
        return this.fetchJSON(`${BASE_URL}/order/${hash}`).then(response => {
            return response.data
        })
    }

    seo(location) {
        return this.fetchJSON(`${BASE_URL}/seo?url=${location}`).then(response => {
            return response.data
        })
    }
}