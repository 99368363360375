import styles from "./components/modals/Wrapper.module.css";

export function classNames(...str) {
    return [...str,].join(" ")
}

export function truncateText(text, width) {
    const textWidth = 12 * text.length

    if (textWidth > width) {
        const avg = textWidth / text.length
        const desired = Math.ceil(width / avg)
        if (desired > 0) {
            return text.slice(0, desired) + '...'
        }
    }

    return text
}

export const unmaskDigits = (digits) => {
    if (!digits) {
        return ""
    }

    const dd = digits?.match(/\d+/g)
    if (!dd) {
        return ""
    }

    return dd.join('') || "";
}