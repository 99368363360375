import React, {createRef} from "react";
import InputMask from "react-input-mask";

import {AppContext} from "../../store/context";
import {RedButton} from "../../components/common/Buttons";
import {classNames} from "../../helper";
import {Question, QuestionStars, QuestionTextarea} from "../../components/common/Form";

import image from '../../static/images/forms/agent-request.png'

import styles from "./AgentDelivery.module.css";
import back from "../../static/icons/back.svg";

export default class AgentDelivery extends React.Component {
    constructor() {
        super();
        this.form = createRef()

        this.state = {
            proceed: false,
            step: 1,

            phone: null,
            name: null,

            checkbox_1: null,
            checkbox_2: null,
            checkbox_3: null,
            checkbox_4: null,
            checkbox_5: null,
            checkbox_6: null,
            checkbox_7: null,


            comment: '',

            taste: 1,
            atmosphere: 1,
            recommend: 1

        }

    }

    prevStep() {
        window.scrollTo({top: this.form.current.offsetTop - 20, left: 0, behavior: "smooth"});

        this.setState({step: this.state.step - 1})
    }

    progressClass() {
        switch (this.state.step) {
            case 1:
                return classNames(styles.progress, styles.progress_1)
            case 2:
                return classNames(styles.progress, styles.progress_2)
            case 3:
                return classNames(styles.progress, styles.progress_3)
            case 4:
                return classNames(styles.progress, styles.progress_4)
        }
    }

    nextStep() {
        switch (this.state.step) {
            case 1:
                const phoneUnmasked = this.state.phone?.match(/\d+/g).join('') || "";
                if (phoneUnmasked.length < 11) {
                    this.context.context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
                    return;
                }

                if (!this.state.name) {
                    this.context.context.toast.error('Пожалуйста, укажите ваше имя');
                    return;
                }

                break;
            case 2:
                if (
                    this.state.checkbox_1 === null ||
                    this.state.checkbox_2 === null ||
                    this.state.checkbox_3 === null ||
                    this.state.checkbox_4 === null
                ) {
                    this.context.context.toast.error('Пожалуйста, укажите ответы на все вопросы');
                    return;
                }
                break;
            case 3:
                if (
                    this.state.checkbox_5 === null ||
                    this.state.checkbox_6 === null ||
                    this.state.checkbox_7 === null
                ) {
                    this.context.context.toast.error('Пожалуйста, укажите ответы на все вопросы');
                    return;
                }
                break;
        }


        window.scrollTo({top: this.form.current.offsetTop - 20, left: 0, behavior: "smooth"});

        this.setState({step: this.state.step + 1})
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    send() {
        this.context.context.processor.form
            .agentDeliveryReport(this.state)
            .then(res => this.setState({proceed: true}))
            .catch(err => this.context.context.toast.error(err.message))
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleText}>тайный агент: доставка</div>
                <div className={styles.titleSub}>Ваши идеи помогают нам улучшать сервис</div>
            </div>
            <div className={styles.inner}>
                {this.state.proceed ?
                    <div className={styles.description}>
                        <div className={styles.image}>
                            <img src={image} alt=""/>
                        </div>
                        <div className={styles.typo}>
                            <div className={styles.head}>
                                <span>Спасибо, что заполнили отчёт по тайному агенту!</span>
                            </div>
                            <div className={styles.text}>
                                В течение двух дней с Вами свяжется наш менеджер службы качества.
                            </div>
                            <div className={styles.button}>
                                <RedButton onClick={() => this.props.history.push("/")} text={"на главную"}/>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={styles.form} ref={this.form}>
                        <div className={styles.formSteps}>
                            <div className={styles.steps}>
                                <div className={styles.stepTitle}>заполнено {this.state.step - 1}/4</div>
                                <div className={this.progressClass()}/>
                                <div className={styles.stepList}>
                                    <div
                                        className={classNames(styles.step, this.state.step === 1 ? styles.active : null)}>
                                        Контактная информация
                                    </div>
                                    <div
                                        className={classNames(styles.step, this.state.step === 2 ? styles.active : null)}>
                                        Первое впечатление
                                    </div>
                                    <div
                                        className={classNames(styles.step, this.state.step === 3 ? styles.active : null)}>
                                        Сервис
                                    </div>
                                    <div
                                        className={classNames(styles.step, this.state.step === 4 ? styles.active : null)}>
                                        Общее впечатление
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.survey}>
                            <div className={classNames(styles.block, this.state.step === 1 ? styles.active : '')}>
                                <div className={styles.common}>
                                    <div className={styles.commonQuestion}>
                                        <div className={styles.commonTitle}>Ваше имя *</div>
                                        <div className={styles.commonAnswer}>
                                            <input type="text" value={this.state.name} className={styles.input}
                                                   placeholder={"Ваше имя"}
                                                   onChange={(e) => this.setState({name: e.currentTarget.value})}/>
                                        </div>
                                    </div>
                                    <div className={styles.commonQuestion}>
                                        <div className={styles.commonTitle}>Номер телефона, на который был сделан заказ
                                            *
                                        </div>
                                        <div className={styles.commonAnswer}>
                                            <InputMask className={styles.input}
                                                       value={this.state.phone}
                                                       formatChars={{
                                                           '9': '[0-9]',
                                                           '7': '[7-8]'
                                                       }}
                                                       readonly={true}
                                                       mask="+7 (999) 999-99-99"
                                                       placeholder={'Номер телефона'}
                                                       onChange={(e) => this.setState({phone: e.currentTarget.value})}
                                                       maskChar="_"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.nextButton}>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 2 ? styles.active : '')}>
                                <div className={styles.blockTitle}>Первое впечатление</div>
                                <div className={styles.questions}>
                                    <Question
                                        text={"Курьер поздоровался, вел себя вежливо и доброжелательно"}
                                        value={this.state.checkbox_1}
                                        onNo={() => this.setState({checkbox_1: 0})}
                                        onYes={() => this.setState({checkbox_1: 1})}
                                    />
                                    <Question
                                        text={"Заказ доставили аккуратно, ничего не пролилось, упаковка была удобной"}
                                        value={this.state.checkbox_2}
                                        onNo={() => this.setState({checkbox_2: 0})}
                                        onYes={() => this.setState({checkbox_2: 1})}
                                    />
                                    <Question
                                        text={"Заказ был доставлен вовремя, без опозданий (Укажите время опоздания при необходимости. Предупредили ли Вас об опоздании?)"}
                                        value={this.state.checkbox_3}
                                        onNo={() => this.setState({checkbox_3: 0})}
                                        onYes={() => this.setState({checkbox_3: 1})}
                                    />
                                    <Question
                                        text={"Приборы положили к заказу в нужном количестве"}
                                        value={this.state.checkbox_4}
                                        onNo={() => this.setState({checkbox_4: 0})}
                                        onYes={() => this.setState({checkbox_4: 1})}
                                    />
                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 3 ? styles.active : '')}>
                                <div className={styles.blockTitle}>Сервис доставки</div>
                                <div className={styles.questions}>
                                    <Question
                                        text={"Комплектация заказа была верной, привезли все заказанные блюда и все соусы к ним"}
                                        value={this.state.checkbox_5}
                                        onNo={() => this.setState({checkbox_5: 0})}
                                        onYes={() => this.setState({checkbox_5: 1})}
                                    />

                                    <Question
                                        text={"Все блюда были горячие/теплые (помимо салатов, десертов и напитков)"}
                                        value={this.state.checkbox_6}
                                        onNo={() => this.setState({checkbox_6: 0})}
                                        onYes={() => this.setState({checkbox_6: 1})}
                                    />
                                    <Question
                                        text={"Внешний вид блюд был надлежащего качества"}
                                        value={this.state.checkbox_7}
                                        onNo={() => this.setState({checkbox_7: 0})}
                                        onYes={() => this.setState({checkbox_7: 1})}
                                    />
                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.nextStep()} text={"Далее"}/>
                                </div>
                            </div>
                            <div className={classNames(styles.block, this.state.step === 4 ? styles.active : '')}>
                                <div className={styles.questions}>
                                    <QuestionStars value={this.state.atmosphere}
                                                   max={5}
                                                   onSelect={(val) => this.setState({atmosphere: val})}
                                                   text={"Насколько комфортно Вам было общаться с нашими сотрудниками"}/>
                                    <QuestionStars value={this.state.taste}
                                                   max={5}
                                                   onSelect={(val) => this.setState({taste: val})}
                                                   text={"Все блюда понравились на вкус"}/>
                                    <QuestionStars value={this.state.recommend}
                                                   max={5}
                                                   onSelect={(val) => this.setState({recommend: val})}
                                                   text={"Оцените общее впечатление"}/>
                                    <QuestionTextarea text={"Комментарии к качеству доставки"}
                                                      value={this.state.comment}
                                                      onChange={(e) => this.setState({comment: e.currentTarget.value})}/>
                                </div>
                                <div className={classNames(styles.nextButton, styles.nextWithBack, styles.finalButton)}>
                                    <div className={styles.back} onClick={() => this.prevStep()}>
                                        <img src={back} alt=""/>
                                        <span>назад</span>
                                    </div>
                                    <RedButton onClick={() => this.send()} text={"завершить отчет"}/>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    }
}

AgentDelivery.contextType = AppContext;