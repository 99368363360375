import {useContext, useEffect, useState} from "react";
import InputMask from "react-input-mask";
import Calendar from "react-calendar";

import {AppContext} from "../../store/context";
import {classNames} from "../../helper";
import {RedButton} from "../common/Buttons";

import envelope from '../../static/icons/envelope.svg'
import cross from "../../static/icons/cross.svg";
import arrowRight from "../../static/icons/cart/arrow-right.svg";
import arrowLeft from "../../static/icons/cart/arrow-left.svg";
import address_logo from "../../static/icons/catalog/address-logo.svg";

import styles from "./Restaurant.module.css";
import "../../static/css/react-calendar.css"

export const ResponsiveRestaurantModal = () => {
    const {context} = useContext(AppContext);

    const onDismiss = () => {
        context.modals.restaurant.dispatch({type: 'close'});
    };

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    const isOpen = context.modals.restaurant.state.open

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
            }, 400)
        }
    }, [isOpen])

    return <div className={classNames(styles.modal, (open ? styles.modalOpen : ''))}
                style={{display: display ? "flex" : "none"}}>
        <div className={styles.cross} onClick={() => onDismiss()}>
            <img src={cross} alt=""/>
        </div>
        <RestaurantModal/>
    </div>
}

export const RestaurantModal = () => {
    const {context} = useContext(AppContext)
    const [success, setSuccess] = useState(false)

    const today = new Date()
    const minDate = new Date()
    const maxDate = new Date()

    minDate.setDate(today.getDate() + 1);
    maxDate.setDate(today.getDate() + 14);

    useEffect(() => {
        if (context.modals.restaurant.state.open) {
            setSuccess(false)
            setData({
                name: null,
                phone: null,
                comment: null,
                persons: null,
                date: minDate,
                time: null
            })
        }
    }, [context.modals.restaurant.state.open])

    const [data, setData] = useState({
        name: null,
        phone: null,
        comment: null,
        persons: null,
        date: minDate,
        time: null
    })
    const book = () => {
        const phoneUnmasked = data.phone?.match(/\d+/g).join('') || "";
        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (!data.name || data.name.length < 1) {
            context.toast.error('Пожалуйста, проверьте корректность введенного имени');
            return;
        }

        const timeUnmasked = data.time?.match(/\d+/g).join('') || "";
        if (timeUnmasked.length < 4) {
            context.toast.error('Пожалуйста, проверьте корректность времени');
            return;
        }

        if (timeUnmasked <= 1159 || timeUnmasked >= 2130) {
            context.toast.error('Бронь столов не доступна с 21:30 до 11:59');
            return;
        }

        if (!data.persons || data.persons < 1) {
            context.toast.error('Пожалуйста, укажите количество персон');
            return;
        }

        const dateFormatted = `${new Intl.DateTimeFormat("ru", {
            day: "2-digit",
            month: "long"
        }).format(data.date)} ${data.date.getFullYear()}`

        const dateFormattedDigit = `${data.date.getDate()}.${('0' + (data.date.getMonth() + 1)).slice(-2)}.${data.date.getFullYear()}`;

        const request = {
            restaurant: context.modals.restaurant.state.selected.slug,
            name: data.name,
            comment: data.comment,
            persons: data.persons,
            phone: phoneUnmasked,
            date: dateFormatted,
            dateInDigit: dateFormattedDigit,
            time: data.time
        }

        context.processor.form.booking(request).then(res => {
            setSuccess(true)
        }).catch(err => {
            context.toast.error(err.message)
        })
    }

    const dateBlocked = context.modals.restaurant.state.selected?.blocked;
    const compareBlockedDates = (date) => {
        if (dateBlocked) {
            return dateBlocked.includes(`${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`);
        }
        return false;
    };

    return success
        ? <div className={styles.success}>
            <div className={styles.successTitle}>
                <img className={styles.successTitleIcon} src={envelope} alt=""/>
                <div className={styles.successTitleText}>заявка отправлена</div>
            </div>
            <div className={styles.successText}>
                Менеджер ресторана свяжется с Вами по указанному номеру телефона для подтверждения брони.
            </div>
            <div className={styles.successWarn}>
                Если вам не позвонили, пожалуйста, обратитесь в колл-центр по номеру 603-47-47
            </div>
            <div className={styles.successGrid}>
                <div className={styles.successGridItem}>
                    <div className={styles.successGridTitle}>адрес ресторана</div>
                    <div
                        className={styles.successGridValue}>{context.modals.restaurant.state.selected?.address}</div>
                </div>
                <div className={styles.successGridItem}>
                    <div className={styles.successGridTitle}>когда</div>
                    <div className={styles.successGridValue}>{`${new Intl.DateTimeFormat("ru", {
                        day: "2-digit",
                        month: "2-digit"
                    }).format(data.date)} ${data.date.getFullYear()} в ${data.time}`}
                    </div>
                </div>
                <div className={styles.successGridItem}>
                    <div className={styles.successGridTitle}>{data.name}</div>
                    <div className={styles.successGridValue}>{data.phone}</div>
                </div>
                <div className={styles.successGridItem}>
                    <div className={styles.successGridTitle}>кол-во персон</div>
                    <div className={styles.successGridValue}>{data.persons}</div>
                </div>
            </div>
        </div>
        : context.settings.isMobile ? <div className={styles.inner}>
            <div className={styles.head}>
                <div className={styles.title}>{context.modals.restaurant.state.selected?.name}</div>
                <div className={styles.subTitle}>{context.modals.restaurant.state.selected?.address}</div>
            </div>
            <div className={styles.restaurantSelectorItems}>
                {context.common.state.restaurants.map(el => {
                    return <div
                        className={classNames(styles.restaurantSelectorItem, el.slug === context.modals.restaurant.state.selected?.slug ? styles.active : styles.inactive)}
                        onClick={() => {
                            context.modals.restaurant.dispatch({type: "select", payload: el})
                        }}>
                        <div className={styles.restaurantSelectorIcon}>
                            <img src={address_logo} alt=""/>
                        </div>
                        <div className={styles.restaurantSelectorItemText}>{el.name}</div>
                    </div>
                })}
            </div>
            <div className={styles.calendar}>
                <Calendar
                    formatMonthYear={(locale, date) => `${new Intl.DateTimeFormat("ru", {month: "long"}).format(date)} ${date.getFullYear()}`}
                    nextLabel={<img src={arrowRight} alt=""/>}
                    next2Label={null}
                    prevLabel={<img src={arrowLeft} alt=""/>}
                    prev2Label={null}
                    minDate={minDate}
                    maxDate={maxDate}
                    value={data.date}
                    onChange={(date) => setData({...data, date: date})}
                    minDetail={"month"}
                    maxDetail={"month"}
                    tileDisabled={({date}) => compareBlockedDates(date)}
                />
            </div>
            <div className={styles.row}>
                <div className={styles.calendarDate}>
                    Дата: {`${new Intl.DateTimeFormat("ru", {
                    day: "2-digit",
                    month: "long"
                }).format(data.date)} ${data.date.getFullYear()}`}
                </div>
            </div>
            <div className={styles.date}>
                <div className={styles.col}>
                    <div className={styles.inputRed}>
                        <label htmlFor="">Введите время</label>
                        <InputMask
                            formatChars={{
                                '1': '[0-2]',
                                '2': data.time?.startsWith('2') ? '[0-3]' : '[0-9]',
                                '3': '[0-5]',
                                '4': '[0-9]'
                            }}
                            onChange={(e) => setData({...data, time: e.currentTarget.value})}
                            alwaysShowMask={true}
                            mask={'12:34'}
                            value={data.time}/>
                    </div>
                    <div className={styles.inputRed}>
                        <label htmlFor="">Количество персон</label>
                        <input type="number"
                               placeholder={"__"}
                               min={1}
                               value={data.persons}
                               onChange={(e) => setData({...data, persons: e.currentTarget.value})}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.form}>
                <div className={styles.restaurantForm}>
                    <div className={styles.row}>
                        <input type="text"
                               onChange={(e) => setData({...data, name: e.currentTarget.value})}
                               placeholder={'Ваше имя'}
                               autoComplete={"name"}
                               value={data.name}
                               className={styles.input}/>

                    </div>
                    <div className={styles.row}>
                        <InputMask className={styles.input}
                                   value={data.phone}
                                   formatChars={{
                                       '9': '[0-9]',
                                       '7': '[7-8]'
                                   }}
                                   onChange={e => setData({...data, phone: e.currentTarget.value})}
                                   mask="+7 (999) 999-99-99"
                                   placeholder={'Номер телефона'}
                                   maskChar="_"/>
                    </div>
                    <div className={styles.row}>
                            <textarea
                                onChange={(e) => setData({...data, comment: e.currentTarget.value})}
                                placeholder={'Комментарий'}
                                value={data.comment}
                                className={styles.textarea}/>
                    </div>
                    <div className={styles.button}>
                        <RedButton onClick={() => book()} text={'забронировать'}/>
                    </div>
                </div>
            </div>
        </div> : <div className={styles.inner}>
            <div className={styles.date}>
                <div className={styles.head}>
                    <div className={styles.title}>{context.modals.restaurant.state.selected?.name}</div>
                    <div className={styles.subTitle}>{context.modals.restaurant.state.selected?.address}</div>
                </div>
                <div className={styles.calendar}>
                    <Calendar
                        className={'react-calendar-table'}
                        formatMonthYear={(locale, date) => `${new Intl.DateTimeFormat(locale, {month: "long"}).format(date)} ${date.getFullYear()}`}
                        nextLabel={<img src={arrowRight} alt=""/>}
                        next2Label={null}
                        prevLabel={<img src={arrowLeft} alt=""/>}
                        prev2Label={null}
                        minDate={minDate}
                        maxDate={maxDate}
                        value={data.date}
                        onChange={(date) => setData({...data, date: date})}
                        minDetail={"month"}
                        maxDetail={"month"}
                        tileDisabled={({date}) => compareBlockedDates(date)}
                    />
                </div>
                <div className={styles.row}>
                    <span className={styles.calendarDate}>
                          Дата: {`${new Intl.DateTimeFormat("ru", {
                        day: "2-digit",
                        month: "long"
                    }).format(data.date)} ${data.date.getFullYear()}`}
                    </span>
                </div>
            </div>
            <div className={styles.form}>
                <div className={styles.restaurantSelectorItems}>
                    {context.common.state.restaurants.map(el => {
                        return <div
                            className={classNames(styles.restaurantSelectorItem, el.slug === context.modals.restaurant.state.selected?.slug ? styles.active : styles.inactive)}
                            onClick={() => {
                                context.modals.restaurant.dispatch({type: "select", payload: el})
                            }}>
                            <div className={styles.restaurantSelectorIcon}>
                                <img src={address_logo} alt=""/>
                            </div>
                            <div className={styles.restaurantSelectorItemText}>{el.name}</div>
                        </div>
                    })}
                </div>
                <div className={styles.restaurantForm}>
                    <div className={styles.row}>
                        <input type="text"
                               onChange={(e) => setData({...data, name: e.currentTarget.value})}
                               placeholder={'Ваше имя'}
                               autoComplete={"name"}
                               value={data.name}
                               className={styles.input}/>
                        <InputMask className={styles.input}
                                   value={data.phone}
                                   formatChars={{
                                       '9': '[0-9]',
                                       '7': '[7-8]'
                                   }}
                                   onChange={e => setData({...data, phone: e.currentTarget.value})}
                                   mask="+7 (999) 999-99-99"
                                   placeholder={'Номер телефона'}
                                   maskChar="_"/>
                    </div>
                    <div className={styles.row}>
                            <textarea
                                onChange={(e) => setData({...data, comment: e.currentTarget.value})}
                                placeholder={'Комментарий'}
                                value={data.comment}
                                className={styles.textarea}/>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.inputRed}>
                            <label htmlFor="">Введите время</label>
                            <InputMask
                                formatChars={{
                                    '1': '[0-2]',
                                    '2': data.time?.startsWith('2') ? '[0-3]' : '[0-9]',
                                    '3': '[0-5]',
                                    '4': '[0-9]'
                                }}
                                onChange={(e) => setData({...data, time: e.currentTarget.value})}
                                alwaysShowMask={true}
                                mask={'12:34'}
                                value={data.time}/>
                        </div>
                        <div className={styles.inputRed}>
                            <label htmlFor="">Количество персон</label>
                            <input type="number"
                                   placeholder={"__"}
                                   min={1}
                                   value={data.persons}
                                   onChange={(e) => setData({...data, persons: e.currentTarget.value})}
                            />
                        </div>
                    </div>
                    <div className={styles.button}>
                        <RedButton onClick={() => book()} text={'забронировать'}/>
                    </div>
                </div>
            </div>
        </div>
}
