import React from 'react'

import styles from './Statistics.module.css'
import img from '../../../../static/images/10-let-lubvi/сердце наши 10 лет.png'
import {useHistory} from "react-router-dom";

export const Statistics = () => {

    const history = useHistory();

    return <div className={styles.container}>
        <div className={styles.title}>
            <h2 className={styles.head}>Наши 10 лет это:</h2>
            <div className={styles.subtitleWrapper}>
                <span className={styles.subtitle}>10년 동안 사랑한다</span>
            </div>
        </div>
        <div className={styles.content}>
            <div className={styles.contentStatistics}>
                <div className={styles.contentStatisticsList}>
                    <p className={styles.contentStatisticsItem}>
                        <span className={styles.contentStatisticsQuantity}>1 000 000</span>
                        килограмм салата кимчи
                    </p>
                    <p className={styles.contentStatisticsItem}>
                        <span className={styles.contentStatisticsQuantity}>3 000 000</span>
                        гостей
                    </p>
                    <p className={styles.contentStatisticsItem}>
                        <span className={styles.contentStatisticsQuantity}>500 000</span>
                        пибимпабов
                    </p>
                    <p className={styles.contentStatisticsItem}>
                        <span className={styles.contentStatisticsQuantity}>160 000</span>
                        куксу
                    </p>
                    <p className={styles.contentStatisticsItem}>
                        <span className={styles.contentStatisticsQuantity}>10</span>
                        лет любви...
                    </p>
                </div>
                <p className={styles.contentStatisticsDescription}>
                    ...каждый день меняя и улучшая наш <br /> мир, делая его добрее, светлее, вкуснее
                </p>
            </div>
            <div className={styles.contentImage}>
                <img src={img} alt={"best_moment"} />
            </div>
        </div>
        <div className={styles.buttonContainer}>
            <div className={styles.button} onClick={() => history.push("/10-let-lubvi")}>Подробнее</div>
        </div>
    </div>
}
