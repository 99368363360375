import React, {useContext, useRef} from 'react'

import styles from './PhotoSlider.module.css';
import './Slick.css';

import { AppContext } from "../../../../store/context";

import Slider from "react-slick";
import arrow_left from "../../../../static/icons/arrow_left.svg";
import arrow_right from "../../../../static/icons/arrow_right.svg";

export const PhotoSlider = () => {
    const {context} = useContext(AppContext)
    const ref = useRef();

    const settings = {
        slidesToShow: context.settings.isMobile ? 1 : 4,
        speed: 500,
        arrows: false,
        draggable: true,
        variableWidth: true,
        className: styles.slider__carousel,
    };

    const next = () => {
        ref.current.slickNext();
    }

    const prev = () => {
        ref.current.slickPrev();
    }

    return <div className={styles.container}>
        <div className={styles.slider}>
           <div className={styles.slider__header}>
               <div className={styles.slider__title}>Как это было в прошлом году:</div>
               <div className={styles.slider__icons}>
                   <div className={styles.slider__iconLeft} onClick={() => {prev()}}>
                       <img src={arrow_left} alt=""/>
                   </div>
                   <div className={styles.slider__iconRight} onClick={() => {next()}}>
                       <img src={arrow_right} alt=""/>
                   </div>
               </div>
           </div>
            <div className={styles.slider__slides}>
                <Slider ref={c => (ref.current = c)} {...settings}>
                    {context.common.state.landing.slider.slides.map(el => {
                        return <SliderItem el={el} key={el.id}/>
                    })}
                </Slider>
            </div>
        </div>
    </div>
}

export const SliderItem = ({el}) => {

    return <div className={styles.slider__item}>
        <img src={el.img} alt="image" />
    </div>
}
