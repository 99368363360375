import React, {createRef, useContext, useEffect, useState} from "react";
import InputMask from "react-input-mask";

import {RedButton} from "./Buttons";
import {AppContext} from "../../store/context";
import {classNames} from "../../helper";

import attach from '../../static/icons/attach.svg'
import star from "../../static/icons/star.svg";
import address_logo from "../../static/icons/catalog/address-logo.svg";

import styles from './Form.module.css'

export const Checkbox = ({className, checked, onClick, text}) => {
    className = className || ''

    return <div className={classNames(className, styles.checkbox)} onClick={onClick}>
        <div className={styles.checkboxBox}>
            {checked ? <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15.6886 0.113919C16.0279 0.306036 16.1013 0.674154 15.8524 0.936132L7.47153 9.75963C7.3325 9.90601 7.11371 9.99463 6.87869 9.99976C6.64367 10.0049 6.41875 9.92598 6.2692 9.7859L0.173994 4.07658C-0.0936412 3.82589 -0.0473774 3.45516 0.277327 3.24853C0.602031 3.0419 1.08222 3.07762 1.34985 3.32831L6.82202 8.45403L14.6236 0.240413C14.8725 -0.0215655 15.3493 -0.078199 15.6886 0.113919Z"
                      fill="#6A6B6B"/>
            </svg> : null}
        </div>
        <span>{text}</span>
    </div>
}

export const AgreementCheckbox = ({checked, onClick}) => {
    return <div className={classNames(styles.agreement, styles.checkbox)}>
        <div className={styles.checkboxBox} onClick={onClick}>
            {checked ? <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15.6886 0.113919C16.0279 0.306036 16.1013 0.674154 15.8524 0.936132L7.47153 9.75963C7.3325 9.90601 7.11371 9.99463 6.87869 9.99976C6.64367 10.0049 6.41875 9.92598 6.2692 9.7859L0.173994 4.07658C-0.0936412 3.82589 -0.0473774 3.45516 0.277327 3.24853C0.602031 3.0419 1.08222 3.07762 1.34985 3.32831L6.82202 8.45403L14.6236 0.240413C14.8725 -0.0215655 15.3493 -0.078199 15.6886 0.113919Z"
                      fill="#6A6B6B"/>
            </svg> : null}
        </div>
        <span>Согласен с <a href="/confidential" target={"_blank"}>Политикой обработки персональных данных</a> и <a
                href="/confidential" target={"_blank"}>Соглашением об условиях доставки</a>
        </span>
    </div>
}

export const Question = ({text, onYes, onNo, value}) => {
    return <div className={styles.question}>
        <div className={styles.questionTitle}>{text}</div>
        <div className={styles.questionAnswers}>
            <div className={classNames(styles.questionAnswer, value === 1 ? styles.active : '')} onClick={onYes}>Да
            </div>
            <div className={classNames(styles.questionAnswer, value === 0 ? styles.active : '')} onClick={onNo}>Нет
            </div>
        </div>
    </div>
}

export const QuestionTextarea = ({text, onChange, value}) => {
    return <div className={styles.question}>
        <div className={styles.questionTitleBold}>{text}</div>
        <div className={styles.questionAnswers}>
            <textarea className={styles.textarea} placeholder={'Развернутый ответ'} value={value} onChange={onChange}/>
        </div>
    </div>
}

export const QuestionText = ({text, onChange, value}) => {
    return <div className={styles.question}>
        <div className={styles.questionTitleBold}>{text}</div>
        <div className={styles.questionAnswers}>
            <input className={styles.input} placeholder={'Введите значение'} value={value} onChange={onChange}/>
        </div>
    </div>
}

export const QuestionStars = ({text, max, onSelect, value}) => {
    const items = Array.from({length: max}, (v, k) => k + 1)

    return <div className={classNames(styles.question, styles.questionStars)}>
        <div className={styles.questionTitleBold}>{text} {max > 5 ? <span>(от 1 до {max})</span> : null}</div>
        <div className={styles.questionAnswers}>
            {items.map(x =>
                <div className={classNames(styles.questionStar, value >= x ? styles.active : '')}
                     onClick={() => onSelect(x)}>
                    <img src={star} alt=""/>
                    {/*<span>{x}</span>*/}
                </div>
            )}
        </div>
    </div>
}

export const QuestionStarsWithQuestions = ({text, max, onSelect, value}) => {
    const items = Array.from({length: max}, (v, k) => k + 1)

    return <div className={classNames(styles.question, styles.questionStars)}>
        <div className={styles.questionTitleBold}>{text} {max > 5 ? <span>(от 1 до {max})</span> : null}</div>
        <div className={styles.questionAnswers}>
            {items.map(x =>
                <div className={classNames(styles.questionStar, value >= x ? styles.active : '')}
                     onClick={() => onSelect(x)}>
                    <img src={star} alt=""/>
                    {/*<span>{x}</span>*/}
                </div>
            )}
        </div>
    </div>
}

export const QuestionFilesImages = ({onChange}) => {
    const [images, setImages] = useState([]);
    const [isError, setIsError] = useState(false);

    const IMAGE_VALID_TYPES = ['image/jpeg', 'image/png'];
    const IMAGE_SIZE_MAX = 5; // mb
    const IMAGE_FILES_MAX = 10;

    const handleFileChange = (evt) => {
        setIsError(false);
        const files = evt.target.files;
        const filesTemp = []

        if ((files.length + images.length) > IMAGE_FILES_MAX) {
            setIsError(true);
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const fileType = files[i]['type'];
            const fileSize = (files[i]['size'] / (1024 * 1024)).toFixed(2);

            if (!IMAGE_VALID_TYPES.includes(fileType) || fileSize > IMAGE_SIZE_MAX) {
                setIsError(true);
                return;
            }

            filesTemp.push(files[i]);
        }

        setImages([...images, ...filesTemp]);
    }

    const handleRemoveImageClick = (index) => {
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
     }

    useEffect(() => {
        onChange(images);
    }, [images]);

    return <div className={styles.questionImages}>
        <div className={styles.questionImagesContainer}>
            {images.length < 10 &&
            <div className={styles.questionImagesInputContainer}>
                <label className={styles.questionImagesLabel}>
                    <input type="file" className={styles.questionImagesInput} onChange={handleFileChange} multiple name="files[]" accept="image/png, image/jpeg" />
                    <span className={styles.questionImagesLabelText}>Прикрепить фотографии</span>
                </label>
                <p className={styles.questionImagesTextLimit}>Вы можете загрузить до 10 фотографий размером до 5 МБ.</p>
            </div>}
            {isError &&
            <div className={styles.questionImagesTextErrorContainer}>
                <p className={styles.questionImagesTextError}>Количество и размер файлов превышает максимально допустимое значение</p>
            </div>}
            {images.length > 0 &&
            <ul className={styles.questionImagesItemsContainer}>
                {images.map((image, index) => {
                    return (
                        <li key={index} className={styles.questionImagesItem}>
                            <button onClick={() => {handleRemoveImageClick(index)}} className={styles.questionImagesItemBtnDelete} type="button" aria-label="Удалить загруженное изображение"></button>
                            <img className={styles.questionImagesItemImg} src={URL.createObjectURL(image)} width="128" height="128" alt="" />
                        </li>
                    )
                })}
            </ul>}
        </div>
    </div>
}

export const VacancyForm = ({position}) => {
    const {context} = useContext(AppContext)

    position = position || 'Не указана'

    const fileRef = createRef();
    const [data, setData] = useState({name: null, phone: null, email: null, comment: null})
    const [file, setFile] = useState(null);

    const [proceed, setProceed] = useState(false);

    const processForm = () => {
        if (proceed) return

        if (!data.phone || !data.name) {
            context.toast.error('Пожалуйста, проверьте корректность введенных данных');
            return;
        }

        const phoneUnmasked = data.phone?.match(/\d+/g).join('') || "";
        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (!data.name || data.name.length < 1) {
            context.toast.error('Пожалуйста, проверьте корректность введенного имени');
            return;
        }

        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("phone", phoneUnmasked);
        formData.append("email", data.email);
        formData.append("position", position)
        formData.append("comment", data.comment);
        formData.append("file", fileRef.current.files[0]);

        context.processor.form.vacancy(formData).then(res => {
            context.toast.success("Спасибо, сообщение отправлено успешно. Мы обязательно его прочтем и ответим")
            setProceed(true)
        }).catch(err => {
            context.toast.error(err.message)
        })
    };

    return <div className={styles.formHolder}>
        <div className={styles.formRow}>
            <div className={styles.formInput}>
                <input type={'text'}
                       disabled={proceed}
                       placeholder={'Имя'}
                       onChange={(e) => setData({...data, name: e.currentTarget.value})}
                />
            </div>
        </div>
        <div className={styles.formRow}>
            <div className={styles.formInput}>
                <InputMask
                    disabled={proceed}
                    value={data.phone}
                    formatChars={{
                        '9': '[0-9]',
                        '7': '[7-8]'
                    }}
                    onChange={e => setData({...data, phone: e.currentTarget.value})}
                    mask="+7 (999) 999-99-99"
                    placeholder={'Телефон'}
                    maskChar="_"/>
            </div>
            <div className={styles.formInput}>
                <input type={'text'} placeholder={'E-mail'}
                       disabled={proceed}
                       onChange={(e) => setData({...data, email: e.currentTarget.value})}
                />
            </div>
        </div>
        <div className={styles.formRow}>
            <div className={styles.formInput}>
                <textarea rows={8} placeholder={'Сообщение'}
                          disabled={proceed}
                          onChange={(e) => setData({...data, comment: e.currentTarget.value})}
                />
            </div>
        </div>
        <div className={styles.formUpload} onClick={() => {
            fileRef.current.click()
        }}>
            <input
                accept={"application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"}
                style={{width: "1px", height: "1px", visibility: "hidden"}}
                ref={fileRef}
                disabled={proceed}
                onChange={(e) => {
                    return e.target.files.length ? setFile(e.target.files[0].name) : null
                }}
                type="file"/>
            <span>{file ? file : "загрузить резюме"}</span>
            <img src={attach} alt=""/>
        </div>
        <div className={classNames(styles.formRow, styles.button)}>
            <RedButton text={proceed ? "Отправлено успешно!" : 'Отправить'} onClick={() => processForm()}/>
        </div>
    </div>
}

export const RestaurantSelector = ({selected, onSelect}) => {
    const {context} = useContext(AppContext)

    return <div className={styles.restaurants}>
        {context.common.state.restaurants.map(el => {
            return <div
                className={classNames(styles.restaurant, el.name === selected?.name ? styles.active : "")}
                onClick={() => onSelect(el)}>
                <div className={styles.restaurantIcon}>
                    <img src={address_logo} alt=""/>
                </div>
                <div className={styles.restaurantText}>
                    <div className={styles.restaurantTitle}>{el.name}</div>
                    <div className={styles.restaurantAddress}>{el.address}</div>
                </div>
            </div>
        })}
    </div>
}
