import {generatePath, Link} from 'react-router-dom'
import {useContext, useEffect, useState} from "react";

import {RedButton, WhiteButton} from "../../common/Buttons";

import {AppContext} from "../../../store/context";
import {publicRoutes} from "../../../routing";

import logoBullet from '../../../static/icons/logo_bullet.svg'
import logoBulletRed from '../../../static/icons/logo_bullet_red.svg'

import styles from './Restaurants.module.css'
import {STATIC_HOST_GALLERY} from "../../../const";

export const Restaurants = () => {
    const {context} = useContext(AppContext)

    return context.settings.isMobile ? <MobileRestaurants/> : <DesktopRestaurants/>
}
const DesktopRestaurants = () => {
    const {context} = useContext(AppContext)
    const [selected, setSelected] = useState(context.common.state.restaurants[0])

    const [srcImages, setSrcImages] = useState(selected?.gallery.images.slice(selected?.gallery.images.length - 3))
    const [selectedImg, setSelectedImg] = useState(null)

    useEffect(() => {
        setSelectedImg(srcImages[0])
        setSrcImages(srcImages.slice(1))
    }, [])

    useEffect(() => {
        setSelectedImg(selected?.gallery.images[0])
        setSrcImages(selected?.gallery.images.slice(selected?.gallery.images.length - 3))
    }, [selected])

    const changeSlide = (el) => {
        const images = srcImages
        const i = images.findIndex((e) => e === el)

        images[i] = selectedImg

        setSrcImages(images)
        setSelectedImg(el)
    }


    return <div className={styles.container}>
        <div className={styles.texts}>
            <div className={styles.title}>
                <div className={styles.preHead}>레스토랑</div>
                <h2 className={styles.head}>наши РЕСТОРАНЫ</h2>
            </div>
            <div className={styles.text}>В интерьерах наших ресторанов преобладают дерево и природные оттенки.
                Легкость и простота позволяют сосредоточиться на главном – еде.
            </div>
            <div className={styles.list}>
                {context.common.state.restaurants.map(el => {
                    return <div className={styles.listItem} onClick={() => setSelected(el)}>
                        <div className={styles.listItemBullet}>
                            <img src={selected.slug === el.slug ? logoBulletRed : logoBullet} alt=""/>
                        </div>
                        <div className={styles.listItemText}>{el.name}</div>
                    </div>
                })}
            </div>
        </div>
        <div className={styles.restaurant}>
            <div className={styles.restaurantGallery}>
                <div className={styles.galleryPreview}>
                    <img src={STATIC_HOST_GALLERY + selectedImg?.source} alt={selectedImg?.alt}/>
                </div>
                {context.settings.isMobile === false ? <div className={styles.galleryImages}>
                    {srcImages.map(el => {
                        return <div className={styles.galleryImage} onClick={() => {
                            changeSlide(el)
                        }}>
                            <img src={STATIC_HOST_GALLERY + el?.source} alt={el?.alt}/>
                        </div>
                    })}
                </div> : null}
            </div>
            <div className={styles.restaurantInfo}>
                <a target={"_blank"} href={selected.map} className={styles.restaurantInfoItem}>
                    <div className={styles.restaurantInfoHead}>Открыть на Google Maps</div>
                    <div className={styles.restaurantInfoText}>{selected.address}</div>
                    <div className={styles.restaurantInfoSub}>{selected.operating}</div>
                </a>
                <div className={styles.restaurantInfoItem}>
                    <div className={styles.restaurantInfoHead}>Позвонить</div>
                    <a target={"_blank"} href={`tel:${selected.phone}`} className={styles.restaurantInfoText}>
                        <a>{selected.phone}</a>
                    </a>
                    <div className={styles.restaurantInfoSub}>
                        <Link to={generatePath(publicRoutes.restaurant.path, {slug: selected.slug})}>подробнее о
                            ресторане</Link>
                    </div>
                </div>
                <div className={styles.button}>
                    <RedButton text={'меню ресторана'} link={'/menu'}/>
                </div>
            </div>
        </div>
    </div>
}
const MobileRestaurants = () => {
    const {context} = useContext(AppContext)
    const [selected, setSelected] = useState(context.common.state.restaurants[0])

    return <div className={styles.container}>
        <div className={styles.texts}>
            <div className={styles.title}>
                <div className={styles.preHead}>레스토랑</div>
                <div className={styles.head}>наши РЕСТОРАНЫ</div>
            </div>
            <div className={styles.text}>В интерьерах наших ресторанов преобладают дерево и природные оттенки.
                Легкость и простота позволяют сосредоточиться на главном – еде.
            </div>
        </div>
        <div className={styles.inner}>
            <div className={styles.info}>
                <div className={styles.list}>
                    {context.common.state.restaurants.map(el => {
                        return <div className={styles.listItem} onClick={() => {
                            setSelected(el)
                        }}>
                            <div className={styles.listItemBullet}>
                                <img src={selected.slug === el.slug ? logoBulletRed : logoBullet} alt=""/>
                            </div>
                            <div className={styles.listItemText}>{el.name}</div>
                        </div>
                    })}
                </div>
                <div className={styles.restaurantInfo}>
                    <a href={selected.map} target={"_blank"} className={styles.restaurantInfoItem}>
                        <div className={styles.restaurantInfoHead}>Открыть на Google Maps</div>
                        <div className={styles.restaurantInfoText}>{selected.address}</div>
                    </a>
                    <a href={`tel:${selected.phone}`} className={styles.restaurantInfoItem}>
                        <div className={styles.restaurantInfoHead}>Позвонить</div>
                        <div className={styles.restaurantInfoText}>{selected.phone}</div>
                        <div className={styles.restaurantInfoSub}>{selected.operating}</div>
                    </a>
                </div>
            </div>
            <div className={styles.restaurant}>
                <div className={styles.restaurantGallery}>
                    <div className={styles.galleryPreview}>
                        <img src={STATIC_HOST_GALLERY + selected?.gallery.images[0]?.source}
                             alt={selected?.gallery.images[0]?.alt}/>
                    </div>
                </div>
                <div className={styles.button}>
                    <RedButton text={'меню ресторана'} link={'/menu'}/>
                    <WhiteButton text={'заказать столик'} onClick={() => {
                        context.modals.restaurant.dispatch({type: "show", payload: selected})
                    }}/>
                </div>
            </div>
        </div>
    </div>
}
