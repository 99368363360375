import React, {useEffect, useState} from "react";
import styles from "./Restaurant.module.css";
import back from "../../static/icons/conditions/back.svg";
import {generatePath} from "react-router-dom";
import {publicRoutes} from "../../routing";
import {AppContext} from "../../store/context";
import {LoaderFullsize} from "../../components/common/Loader";
import {STATIC_HOST_GALLERY} from "../../const";
import {RedButton, WhiteButton} from "../../components/common/Buttons";

export default class Restaurant extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loaded: false,
            restaurant: null,
        }
    }

    slugFromProps(props) {
        return props.match.params.hasOwnProperty('slug')
            ? props.match.params.slug
            : null
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        const slug = this.slugFromProps(this.props)
        if (!slug) {
            this.props.history.push(generatePath(publicRoutes.notFound.path))
            return
        }

        this.context.context.processor.screen.restaurant(slug).then(res => this.setState({
            restaurant: res.restaurant,
            loaded: true
        })).catch(err => {
            this.props.history.push(generatePath(publicRoutes.notFound.path))
            return
        })
    }

    render() {
        return this.state.loaded ? <div className={styles.container}>
                <div className={styles.title}>
                    <div className={styles.titleBack} onClick={this.props.history.goBack}>
                        <span><img src={back} alt=""/></span>
                        <span>все рестораны</span>
                    </div>
                    <h1 className={styles.titleText}>{this.state.restaurant.title}</h1>
                </div>
                <div className={styles.inner}>
                    {this.context.context.settings.isMobile ?
                        <div className={styles.head}>
                            <div className={styles.aboutOperating}>{this.state.restaurant.operating}</div>
                            <div className={styles.infoParams}>
                                <div className={styles.aboutSect}>
                                    <div className={styles.aboutLabel}>Открыть на Google Maps</div>
                                    <div className={styles.aboutValue}>{this.state.restaurant.address}</div>
                                </div>
                                <div className={styles.aboutSect}>
                                    <div className={styles.aboutLabel}>Позвонить</div>
                                    <div className={styles.aboutValue}>{this.state.restaurant.phone}</div>
                                </div>
                            </div>
                            <div className={styles.buttons}>
                                <RedButton text={"Заказать столик"} onClick={() => {
                                    this.context.context.modals.restaurant.dispatch({
                                        type: "show",
                                        payload: this.state.restaurant
                                    })
                                }}/>
                                <WhiteButton text={"Бонусная программа"} link={"/cashback-sistema-dlya-gostey-koreany"}/>
                            </div>
                            <div className={styles.infoPreview}
                                 dangerouslySetInnerHTML={{__html: this.state.restaurant.preview}}/>
                        </div>
                        : <div className={styles.head}>
                            <div className={styles.info}>
                                <div className={styles.infoParams}>
                                    <div className={styles.aboutSect}>
                                        <div className={styles.aboutLabel}>Открыть на Google Maps</div>
                                        <div className={styles.aboutValue}>{this.state.restaurant.address}</div>
                                    </div>
                                    <div className={styles.aboutSect}>
                                        <div className={styles.aboutLabel}>Позвонить</div>
                                        <div className={styles.aboutValue}>{this.state.restaurant.phone}</div>
                                    </div>
                                    <div className={styles.aboutSect}>
                                        <div className={styles.aboutOperating}>{this.state.restaurant.operating}</div>
                                    </div>
                                </div>
                                <div className={styles.infoPreview}
                                     dangerouslySetInnerHTML={{__html: this.state.restaurant.preview}}/>
                            </div>
                            <div className={styles.buttons}>
                                <RedButton text={"Заказать столик"} onClick={() => {
                                    this.context.context.modals.restaurant.dispatch({
                                        type: "show",
                                        payload: this.state.restaurant
                                    })
                                }}/>
                                <WhiteButton text={"Бонусная программа"} link={"/cashback-sistema-dlya-gostey-koreany"}/>
                            </div>
                        </div>}
                    <RestaurantGallery images={this.state.restaurant.gallery?.images}/>
                    <div className={styles.text} dangerouslySetInnerHTML={{__html: this.state.restaurant.text}}/>
                </div>
            </div> :
            <LoaderFullsize/>
    }
}

const RestaurantGallery = ({images}) => {
    const [srcImages, setSrcImages] = useState(images)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        setSelected(srcImages[0])
        setSrcImages(srcImages.slice(1))
    }, [])

    const changeSlide = (el) => {
        const images = srcImages
        const i = images.findIndex((e) => e === el)

        images[i] = selected

        setSrcImages(images)
        setSelected(el)
    }

    return <div className={styles.gallery}>
        <div className={styles.galleryCol1}>
            <img src={STATIC_HOST_GALLERY + selected?.source} alt={selected?.alt}/>
        </div>
        <div className={styles.galleryCol2}>
            {srcImages.map(el => {
                return <div>
                    <img src={STATIC_HOST_GALLERY + el?.source} alt={el?.alt} onClick={() => {
                        changeSlide(el)
                    }}/>
                </div>
            })}
        </div>
    </div>
}

Restaurant.contextType = AppContext
