import React from 'react'

import styles from './Description.module.css';
import logo from "../../../../static/images/landing/koreana_logo.png";

export const Description = () => {

    return <div className={styles.container}>
        <div className={styles.description}>
            <p className={styles.description__text}>
                Мы приготовили 1 500 000 <br /> порций куксу за 10 лет
            </p>
            <div className={styles.description__img}>
                <img src={logo} alt='logo' />
            </div>
        </div>
    </div>
}
