import React, {createRef} from "react";
import InputMask from "react-input-mask";

import {classNames} from "../../helper";
import {AppContext} from "../../store/context";
import {RedButton} from "../../components/common/Buttons";
import {CalendarSelector, formatDate} from "../../components/common/Calendar";
import {Checkbox, RestaurantSelector} from "../../components/common/Form";

import image from '../../static/images/forms/agent-request.png'
import check from '../../static/icons/checkbox-checked.svg'
import address_logo from "../../static/icons/catalog/address-logo.svg";


import styles from "./AgentRequest.module.css";

export default class AgentRequest extends React.Component {
    constructor() {
        super();
        this.form = createRef()

        this.setDate = this.setDate.bind(this)

        this.state = {
            proceed: false,

            type: null,
            phone: null,
            name: null,
            email: null,
            address: null,
            date: null,
            time: null,
            restaurant: null,

            showDatepicker: false,
            showRestaurants: false,
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    setDate(date, time) {
        this.setState({...this.state, date: date, time: time, showDatepicker: false})
    }

    send() {
        if (this.state.proceed) return;

        const phoneUnmasked = this.state.phone?.match(/\d+/g).join('') || "";
        if (phoneUnmasked.length < 11) {
            this.context.context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }
        if (!this.state.name) {
            this.context.context.toast.error('Пожалуйста, проверьте корректность имени');
            return;
        }

        if (!this.state.email) {
            this.context.context.toast.error('Пожалуйста, укажите email');
            return;
        }

        // if (!this.state.date) {
        //     this.context.context.toast.error('Пожалуйста, выберите предпочитаему дату проверки');
        //     return;
        // }
        //
        // if (!this.state.address) {
        //     this.context.context.toast.error('Пожалуйста, укажите адрес');
        //     return;
        // }

        const data = {...this.state}
        if (this.state.date && this.state.time) {
            data.date = `${formatDate(this.state.date)} в ${this.state.time}`
        } else {
            data.date = null
        }

        this.context.context.processor.form
            .agentRequest(data)
            .then(res => this.setState({proceed: true}))
            .catch(err => this.context.context.toast.error(err.message))
    }

    isValid() {
        return this.state.email && this.state.name && this.state.phone
    }

    render() {
        const today = new Date()
        const minDate = new Date()

        minDate.setDate(today.getDate() + 2);

        return <div className={styles.container}>
            <div className={styles.title}>
                <div
                    className={styles.titleText}>{this.context.context.settings.isMobile ? `тайный агент` : `тайный агент: заявка`}</div>
                <div className={styles.titleSub}>Ваши идеи помогают нам улучшать сервис</div>
            </div>
            <div className={styles.inner}>
                <div className={styles.description}>
                    <div className={styles.image}>
                        <img src={image} alt=""/>
                    </div>
                    <div className={styles.typo}>
                        <div className={styles.head}>
                            <span>Тайные Агенты</span> - это незаменимые помощники
                            в совершенствовании качества нашего обслуживания
                        </div>
                        <div className={styles.text}>
                            В качестве благодарности за проверку ресторана Вы получите сертификат на 1000₽,
                            который можно потратить в любом заведении нашей сети.
                        </div>
                        <div className={styles.text}>
                            В качестве благодарности за проверку нашей службы доставки Вы получите подарок к следующему
                            заказу!

                        </div>
                        <div className={styles.button}>
                            <RedButton onClick={() => window.scrollTo({
                                top: this.form.current.offsetTop - 20,
                                left: 0,
                                behavior: "smooth"
                            })} text={"оставить заявку"}/>
                        </div>
                    </div>
                </div>
                <div className={styles.request} ref={this.form}>
                    <div className={styles.rules}>
                        <div className={styles.rulesHead}>
                            Основные правила миссии:
                        </div>
                        <div className={styles.rulesList}>
                            <div className={styles.rule}>
                                <div className={styles.ruleIcon}>
                                    <img src={check} alt=""/>
                                </div>
                                <div className={styles.ruleText}>Ведите себя естественно и никому не выдавайте, что Вы —
                                    Тайный Агент. Это поможет получить нам максимально честный результат и стать еще
                                    лучше для Вас
                                </div>
                            </div>
                            <div className={styles.rule}>
                                <div className={styles.ruleIcon}>
                                    <img src={check} alt=""/>
                                </div>
                                <div className={styles.ruleText}>
                                    Отправьте анкету проверки в самом конце посещения, после полного заполнения анкет
                                </div>
                            </div>
                            <div className={styles.rule}>
                                <div className={styles.ruleIcon}>
                                    <img src={check} alt=""/>
                                </div>
                                <div className={styles.ruleText}>Наслаждайтесь процессом :)</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.form}>
                        <div className={styles.row}>
                            <input type="text"
                                   placeholder={'Ваше имя'}
                                   value={this.state.name}
                                   onChange={e => this.setState({name: e.currentTarget.value})}
                                   className={styles.input}/>
                        </div>
                        <div className={styles.row}>
                            <InputMask className={styles.input}
                                       value={this.state.phone}
                                       formatChars={{
                                           '9': '[0-9]',
                                           '7': '[7-8]'
                                       }}
                                       onChange={e => this.setState({phone: e.currentTarget.value})}
                                       mask="+7 (999) 999-99-99"
                                       placeholder={'Номер телефона'}
                                       maskChar="_"/>
                            <input type="email"
                                   placeholder={'Ваш email'}
                                   onChange={e => this.setState({email: e.currentTarget.value})}
                                   className={styles.input}/>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.heading}>Хочу проверить в качестве тайного агента:</div>
                        </div>
                        <div className={styles.row}>
                            <Checkbox className={""}
                                      checked={this.state.type === 1}
                                      onClick={() => this.setState({
                                          ...this.state,
                                          type: 1,
                                          address: null,
                                          restaurant: null
                                      })}
                                      text={'Ресторан'}/>
                            <Checkbox className={""}
                                      checked={this.state.type === 2}
                                      onClick={() => this.setState({
                                          ...this.state,
                                          type: 2,
                                          address: null,
                                          restaurant: null
                                      })}
                                      text={'Доставку'}/>
                        </div>
                        {this.state.type === 1 ?
                            <>
                                <div className={classNames(styles.row, styles.selector)}
                                     onClick={() => this.setState({showRestaurants: !this.state.showRestaurants})}
                                >
                                    <input type="text"
                                           placeholder={'Выберите ресторан'}
                                           value={this.state.address}
                                           disabled={true}
                                           className={styles.input}/>
                                </div>
                                {this.state.showRestaurants ? <div className={styles.row}>
                                    <RestaurantSelector selected={this.state.restaurant}
                                                        onSelect={(el) => this.setState({
                                                            address: `${el.name} (${el.address})`,
                                                            restaurant: el,
                                                            showRestaurants: false,
                                                        })}/>
                                </div> : null}
                            </>
                            : <div className={styles.row}>
                                <input type="text"
                                       placeholder={'Укажите адрес доставки'}
                                       value={this.state.address}
                                       onChange={e => this.setState({address: e.currentTarget.value})}
                                       className={styles.input}/>
                            </div>}
                        <div className={classNames(styles.row, styles.selector)}
                             onClick={() => this.setState({...this.state, showDatepicker: !this.state.showDatepicker})}>
                            <input type="text"
                                   placeholder={'Желаемое время проверки'}
                                   disabled={true}
                                   value={this.state.date ? `${formatDate(this.state.date)} в ${this.state.time}` : null}
                                   className={classNames(styles.input)}/>
                        </div>
                        {this.state.showDatepicker ? <div className={styles.row}>
                            <CalendarSelector onSuccess={this.setDate}/>
                        </div> : null}

                        <div className={classNames(styles.row, styles.button, !this.isValid() ? styles.disabled : '')}>
                            <RedButton onClick={() => this.send()}
                                       text={this.state.proceed ? "заявка отправлена" : "отправить"}/>
                        </div>
                        {this.state.proceed ? <div className={styles.row}>
                            <div className={styles.proceedText}>
                                Спасибо за вашу заявку. В течение двух дней с Вами свяжется наш менеджер из службы
                                качества для обсуждения деталей.
                            </div>
                        </div> : null}
                    </div>
                </div>
            </div>
        </div>
    }
}


AgentRequest.contextType = AppContext;