import React from "react";

import {AppContext} from "../../store/context";

import styles from "./Table.module.css";
import {generatePath} from "react-router-dom";
import {publicRoutes} from "../../routing";

export default class Table extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleText}>оставьте отзыв</div>
                <div className={styles.titleSub}>Обратная связь помогает нам стать лучше и <br/>сделать посещение более
                    комфортным для Вас
                </div>
            </div>
            <div className={styles.inner}>
                <div className={styles.buttons}>
                    <div className={styles.button}
                         onClick={() => this.props.history.push(generatePath(publicRoutes.feedback.path))}>
                        <span>оставить отзыв</span>
                    </div>
                    <div className={styles.button}
                         onClick={() => this.props.history.push(generatePath(publicRoutes.agent.path))}>
                        <span>стать тайным агентом</span>
                        <span>возмещаем 1000₽</span>
                    </div>
                </div>
            </div>
        </div>
    }
}

Table.contextType = AppContext;