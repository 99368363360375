import {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {BottomSheet} from "react-spring-bottom-sheet";

import {AppContext} from "../../store/context";
import {classNames} from "../../helper";
import {STATIC_HOST_ACTION} from "../../const";
import {WhiteButton} from "../common/Buttons";
import {ProductModalWrapper} from "./Wrapper";

import cross from "../../static/icons/cross.svg";

import styles from "./Action.module.css";

export const ResponsiveActionModal = () => {
    const {context} = useContext(AppContext);

    const onDismiss = () => {
        context.modals.action.dispatch({type: 'close'});
    };

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    const isOpen = context.modals.action.state.open

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
            }, 400)
        }
    }, [isOpen])

    return context.settings.isMobile
        ? <BottomSheet
            blocking={false}
            expandOnContentDrag={true}
            open={open} onDismiss={onDismiss}
            className={"withPicture"}
            snapPoints={({maxHeight}) => maxHeight - 99}>
            <ActionModal/>
        </BottomSheet>
        : <div className={classNames(styles.modal, (open ? styles.modalOpen : ''))}
               style={{display: display ? "flex" : "none"}}>
            <div className={styles.cross} onClick={() => onDismiss()}>
                <img src={cross} alt=""/>
            </div>
            <ActionModal/>
        </div>
}

const ActionModal = () => {
    const {context} = useContext(AppContext)
    const history = useHistory()
    const navigate = (link) => {
        context.modals.action.dispatch({type: "close"})
        history.push(link)
    }

    return <>
        <div className={styles.inner}>
            <div className={styles.image}>
                <img src={STATIC_HOST_ACTION + context.modals.action.state.action?.image?.source}
                     alt={context.modals.action.state.action?.image?.alt}/>
            </div>
            <div className={styles.body}>
                <div className={styles.title}>{context.modals.action.state.action?.title}</div>
                <div className={styles.text}
                     dangerouslySetInnerHTML={{__html: context.modals.action.state.action?.text}}/>
                <div className={styles.button}>
                    <WhiteButton text={context.modals.action.state.action?.label} onClick={() => {
                        navigate(context.modals.action.state.action?.link)
                    }}/>
                </div>
            </div>
        </div>
    </>
}

export const ResponsiveActionModalOnFuckingSinglePage = ({action, onDismiss, isOpen}) => {
    const {context} = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
                context.modals.actionOnFuckingSinglePage.dispatch({type: "open"})
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
                context.modals.actionOnFuckingSinglePage.dispatch({type: "close"})
            }, 400)
        }
    }, [isOpen])

    return context.settings.isMobile
        ? <BottomSheet
            open={isOpen}
            expandOnContentDrag={true}
            className={"withPicture productCard"}
            onDismiss={onDismiss}
            snapPoints={({maxHeight}) => maxHeight - 100}>
            <ActionModalOnFuckingSinglePage isOpen={isOpen} action={action} onDismiss={onDismiss}/>
        </BottomSheet>
        : <ProductModalWrapper onDismiss={onDismiss} isOpen={isOpen}>
            <div className={classNames(styles.modal, styles.modalOnFuckingSinglePage, (open ? styles.modalOpen : ''))}
                 style={{display: display ? "flex" : "none"}}>
                <div className={styles.cross} onClick={() => onDismiss()}>
                    <img src={cross} alt=""/>
                </div>
                <ActionModalOnFuckingSinglePage action={action}/>
            </div>
        </ProductModalWrapper>

}

const ActionModalOnFuckingSinglePage = ({action}) => {
    const {context} = useContext(AppContext)
    const history = useHistory()

    const navigate = (link) => {
        context.modals.actionOnFuckingSinglePage.dispatch({type: "close"})
        history.push(link)
    }

    return <>
        <div className={styles.inner}>
            <div className={styles.image}>
                <img src={STATIC_HOST_ACTION + action?.image?.source}
                     alt={action?.image?.alt}/>
            </div>
            <div className={styles.body}>
                <div className={styles.title}>{action?.title}</div>
                <div className={styles.text}
                     dangerouslySetInnerHTML={{__html: action?.text}}/>
                <div className={styles.button}>
                    <WhiteButton text={action?.label} onClick={() => {
                        navigate(action?.link)
                    }}/>
                </div>
            </div>
        </div>
    </>
}