import React from 'react'

import styles from './Restaurants.module.css';

import koreanaLogo from '../../../../static/images/landing/restaurants/koreana_logo.png';
import koreanaLightLogo from '../../../../static/images/landing/restaurants/koreana_light_logo.png';
import kimchiLogo from '../../../../static/images/landing/restaurants/kimchi_logo.png';

import arrow from '../../../../static/images/landing/restaurants/right_arrow.png';

export const Restaurants = () => {

    return <div className={styles.container}>
        <div className={styles.restaurants}>
            <a href={'https://koreana.rest/restaurants'} target={'_blank'} className={styles.restaurantsItem}>
                <div className={styles.restaurantsItem__footer}>
                    <img className={styles.restaurantsItem__footerLogo} src={koreanaLogo} alt={'koreanaLogo'} />
                    <div className={styles.restaurantsItem__link}>
                        <p className={styles.restaurantsItem__linkText}>перейти на сайт</p>
                        <img src={arrow} alt={'arrow'} />
                    </div>
                </div>
            </a>
            <a href={'https://koreanalight.ru'} target={'_blank'}  className={styles.restaurantsItem}>
                <div className={styles.restaurantsItem__footer}>
                    <img className={styles.restaurantsItem__footerLogo} src={koreanaLightLogo} alt={'koreanaLightLogo'} />
                    <div className={styles.restaurantsItem__link}>
                        <p className={styles.restaurantsItem__linkText}>перейти на сайт</p>
                        <img src={arrow} alt={'arrow'} />
                    </div>
                </div>
            </a>
            <a href={'https://taplink.cc/kimchitogo'} target={'_blank'} className={styles.restaurantsItem}>
                <div className={styles.restaurantsItem__footer}>
                    <img className={styles.restaurantsItem__footerLogo} src={kimchiLogo} alt={'kimchiLogo'} />
                    <div className={styles.restaurantsItem__link}>
                        <p className={styles.restaurantsItem__linkText}>перейти на сайт</p>
                        <img src={arrow} alt={'arrow'} />
                    </div>
                </div>
            </a>
        </div>
    </div>
}
