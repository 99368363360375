import React, {createContext, useEffect, useReducer, useState} from "react";
import {Processor} from "../api/processor";
import {toast} from 'react-toastify'
import {
    CommonInitialState,
    CommonReducer,
    ProductModalInitialState,
    ProductModalReducer,
    AddressModalInitialState,
    AddressModalReducer,
    CustomerInitialState,
    CustomerReducer,
    CartInitialState,
    CartReducer,
    CartModalInitialState,
    CartModalReducer,
    ActionModalInitialState,
    ActionModalReducer,
    PersonalModalInitialState,
    PersonalModalReducer,
    RestaurantModalInitialState,
    RestaurantModalReducer,
    NavModalInitialState,
    NavModalReducer,
    MenuModalInitialState,
    MenuModalReducer,
    ActionOnFuckingSinglePageModalInitialState,
    ActionOnFuckingSinglePageModalReducer,
    OperatingModalInitialState,
    OperatingModalReducer,
    GiftCertificateModalInitialState,
    GiftCertificateModalReducer,
    AdditionalProductModalInitialState,
    AdditionalProductModalReducer,
    CallBackModalReducer,
    CallBackModalInitialState,
} from './reducers'


export const AppContext = createContext();
export const AppContextValue = () => {
    const [customerState, customerDispatch] = useReducer(CustomerReducer, CustomerInitialState);
    const [commonState, commonDispatch] = useReducer(CommonReducer, CommonInitialState);
    const [productModalState, productModalDispatch] = useReducer(ProductModalReducer, ProductModalInitialState);
    const [additionalProductModalState, additionalProductModalDispatch] = useReducer(AdditionalProductModalReducer, AdditionalProductModalInitialState);
    const [addressModalState, addressModalDispatch] = useReducer(AddressModalReducer, AddressModalInitialState);
    const [cartState, cartDispatch] = useReducer(CartReducer, CartInitialState);
    const [cartModalState, cartModalDispatch] = useReducer(CartModalReducer, CartModalInitialState);
    const [actionModalState, actionModalDispatch] = useReducer(ActionModalReducer, ActionModalInitialState);
    const [personalModalState, personalModalDispatch] = useReducer(PersonalModalReducer, PersonalModalInitialState);
    const [restaurantModalState, restaurantModalDispatch] = useReducer(RestaurantModalReducer, RestaurantModalInitialState);

    const [menuModalState, menuModalDispatch] = useReducer(MenuModalReducer, MenuModalInitialState);
    const [navModalState, navModalDispatch] = useReducer(NavModalReducer, NavModalInitialState);
    const [operatingModalState, operatingModalDispatch] = useReducer(OperatingModalReducer, OperatingModalInitialState);
    const [giftCertificateModalState, giftCertificateModalDispatch] = useReducer(GiftCertificateModalReducer, GiftCertificateModalInitialState);
    const [callBackModalState, callBackModalDispatch] = useReducer(CallBackModalReducer, CallBackModalInitialState);

    const [actionOnFuckingSinglePageModalState, actionOnFuckingSinglePageModalDispatch] = useReducer(ActionOnFuckingSinglePageModalReducer, ActionOnFuckingSinglePageModalInitialState);

    const isMobile = useCheckMobileScreen();

    return {
        settings: {
            isMobile: isMobile
        },
        common: {
            state: commonState,
            dispatch: commonDispatch
        },
        customer: {
            state: customerState,
            dispatch: customerDispatch
        },
        cart: {
            state: cartState,
            dispatch: cartDispatch
        },
        modals: {
            product: {
                state: productModalState,
                dispatch: productModalDispatch,
            },
            additionalProduct: {
                state: additionalProductModalState,
                dispatch: additionalProductModalDispatch,
            },
            address: {
                state: addressModalState,
                dispatch: addressModalDispatch,
            },
            cart: {
                state: cartModalState,
                dispatch: cartModalDispatch,
            },
            action: {
                state: actionModalState,
                dispatch: actionModalDispatch,
            },
            actionOnFuckingSinglePage: {
                state: actionOnFuckingSinglePageModalState,
                dispatch: actionOnFuckingSinglePageModalDispatch,
            },
            personal: {
                state: personalModalState,
                dispatch: personalModalDispatch,
            },
            restaurant: {
                state: restaurantModalState,
                dispatch: restaurantModalDispatch,
            },
            menu: {
                state: menuModalState,
                dispatch: menuModalDispatch,
            },
            nav: {
                state: navModalState,
                dispatch: navModalDispatch,
            },
            operating: {
                state: operatingModalState,
                dispatch: operatingModalDispatch,
            },
            giftCertificate: {
                state: giftCertificateModalState,
                dispatch: giftCertificateModalDispatch,
            },
            callBack: {
                state: callBackModalState,
                dispatch: callBackModalDispatch,
            }
        },
        toast: toast,
        processor: Processor
    }
};

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (width <= 768);
};
