import {useContext, useState, useRef, createElement} from "react";
import {AppContext} from "../../../store/context";
import {classNames} from "../../../helper";

import plus from "../../../static/icons/catalog/plus.svg";
import plusGray from "../../../static/icons/catalog/plus-gray.svg";
import plusRed from "../../../static/icons/catalog/plus-red.svg";
import minus from "../../../static/icons/catalog/minus.svg";

import styles from "./Button.module.css";

const animateToCart = (element, place = 'beforeend', isMobile = false) => {
    const divStyles = {
        display: "block",
        position: "absolute",
        background: "#c52d35",
        borderRadius: '50%',
        width: "45px",
        height: "45px",
        zIndex: "999999",
        visibility: "hidden",
        opacity: 1,
    };

    const div = document.createElement('div');
    div.className = "box-animate"

    for (let style in divStyles) {
      div.style[style] = divStyles[style];
    }

    element.insertAdjacentElement(place, div)

    const x = Math.trunc(Math.sqrt(Math.pow((window.outerWidth - Math.trunc(element.getBoundingClientRect().x)), 2) + Math.pow(Math.trunc(element.getBoundingClientRect().y), 2))) / 2
    const y = Math.trunc(element.getBoundingClientRect().y)

    document.querySelector('.box-animate').animate([
        { transform: 'translate(0, 0)'},
        { transform: `translate(${x}px, ${isMobile ? y : '-' + y}px)`,
        opacity: 0,
        width: 0,
        height: 0,
        visibility: 'visible',
        display: 'none'}
        ], {
        duration: 600,
        iterations: 1,
    });

    setTimeout(() => div.remove(), 1000);
}

export const ProductCardButton = ({product, onClick}) => {
    onClick = onClick || undefined

    const {context} = useContext(AppContext)
    const [progress, setProgress] = useState(false)


    const addToCart = (product, element) => {
        if (onClick && product.options.length) {
            onClick()
            return;
        }

        if (progress) return
        setProgress(true)

        context.processor.cart
            .addProduct({id: product.id})
            .then((response) => {
                context.cart.dispatch({type: 'setCart', payload: response.cart});

                window.dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'add': {
                            'products': [
                                {
                                    'id': product.id,
                                    'name' : product.title,
                                    'price': product.prices.actual,
                                    'category': product.category.title,
                                }
                            ]
                        }
                    }
                });
            })
            .finally(() => {
                setProgress(false);
                animateToCart(element);
            })
            .catch(err => {
                if (err.code === 401) {
                    context.modals.address.dispatch({
                        type: "open",
                        payload: {
                            callback: () => {
                                context.processor.cart
                                    .addProduct({id: product.id})
                                    .then((response) => context.cart.dispatch({
                                        type: 'setCart',
                                        payload: response.cart
                                    }))
                                    .catch(err => context.toast.error(err.message))
                            }
                        }
                    })
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    };
    const minusProduct = (product) => {
        if (progress) return
        setProgress(true)

        context.processor.cart
            .removeProduct({uid: product.uid})
            .then((response) => {
                context.cart.dispatch({type: 'setCart', payload: response.cart})
            })
            .finally(() => setProgress(false))
            .catch(err => {
                if (err.code === 401) {
                    context.modals.address.dispatch({type: 'open'});
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    }

    const plusProduct = (product) => {
        if (progress) return
        setProgress(true)

        context.processor.cart
            .addProduct({uid: product.uid})
            .then((response) => {
                context.cart.dispatch({type: 'setCart', payload: response.cart})
            })
            .finally(() => setProgress(false))
            .catch(err => {
                if (err.code === 401) {
                    context.modals.address.dispatch({type: 'open'});
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    }


    if ((context.cart.state.delivery.zone.group && !product?.zones.find((el) => {
        return el === context.cart.state.delivery.zone.group
    }) ) || !product?.active) {
        return <div className={styles.unavailable}>недоступно</div>
    }

    const pr = Object.values(context.cart.state.products).find(el => {
        return !el.option && el.id == product.id
    })


    if (pr) {
        return <div className={classNames(styles.button, styles.buttonCounter, progress ? styles.progress : '')}>
            <img className={styles.minus} onClick={() => minusProduct(pr)} src={minus} alt=""/>
            <div className={styles.price}>{pr.quantity}</div>
            <img className={styles.plus} onClick={() => plusProduct(pr)} src={plus} alt=""/>
        </div>
    }

    if (product?.prices.old) {
        return <div className={classNames(styles.button, styles.buttonDiscount, progress ? styles.progress : '')}
                    onClick={(evt) => addToCart(product, evt.currentTarget)}>
            <div className={styles.discount}>{product?.prices.old}</div>
            <div className={styles.price}> {product?.prices.actual} ₽</div>
            <img className={styles.plus} src={plus} alt=""/>
        </div>
    }

    return <div className={classNames(styles.button, progress ? styles.progress : '')}
                onClick={(evt) => addToCart(product, evt.currentTarget)}>
        <div className={styles.price}> {product?.prices.actual} ₽</div>
        <img className={styles.plus} src={plus} alt=""/>
    </div>
}

export const ProductButton = ({product, option, disabled}) => {
    disabled = disabled || false
    const {context} = useContext(AppContext)
    const [progress, setProgress] = useState(false)

    const addToCart = (product, element) => {
        if (disabled || progress) {
            if (product.options.length > 0) {
                const itemContent = document.querySelector('div[class^="Product_modifiers__"]');
                if (itemContent) {
                    setTimeout(() => {
                        itemContent.scrollIntoView({behavior: 'smooth', block: 'start'});
                    }, 400)
                }
            }
            return
        }

        const prod = {id: product.id}
        if (option) {
            prod.option = option.id
        }
        setProgress(true)
        context.processor.cart
            .addProduct(prod)
            .then((response) => {
                context.cart.dispatch({type: 'setCart', payload: response.cart});

                window.dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'add': {
                            'products': [
                                {
                                    'id': product.id,
                                    'name' : product.title,
                                    'price': product.prices.actual,
                                    'category': product.category.title,
                                }
                            ]
                        }
                    }
                });
            })
            .finally(() => {
                setProgress(false);
                animateToCart(element);
            })
            .catch(err => {
                if (err.code === 401) {
                    context.modals.address.dispatch({
                        type: "open",
                        payload: {
                            callback: () => {
                                context.processor.cart
                                    .addProduct(prod)
                                    .then((response) => context.cart.dispatch({
                                        type: 'setCart',
                                        payload: response.cart
                                    }))
                                    .catch(err => context.toast.error(err.message))
                            }
                        }
                    })
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    };
    const minusProduct = (product) => {
        if (progress) {
            return
        }

        setProgress(true)
        context.processor.cart
            .removeProduct({uid: product.uid})
            .then((response) => {
                context.cart.dispatch({type: 'setCart', payload: response.cart})
            })
            .finally(() => setProgress(false))
            .catch(err => {
                if (err.code === 401) {
                    context.modals.address.dispatch({type: 'open'});
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    }

    const plusProduct = (product) => {
        if (progress) {
            return
        }

        setProgress(true)
        context.processor.cart
            .addProduct({uid: product.uid})
            .then((response) => {
                context.cart.dispatch({type: 'setCart', payload: response.cart})
            })
            .finally(() => setProgress(false))
            .catch(err => {
                if (err.code === 401) {
                    context.modals.address.dispatch({type: 'open'});
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    }

    if ((context.cart.state.delivery.zone.group && !product?.zones.find((el) => {
        return el === context.cart.state.delivery.zone.group
    })) || !product?.active) {
        return <div className={styles.unavailable}>недоступно</div>
    }

    const pr = Object.values(context.cart.state.products).find(el => {
        return option
            ? el.id === product.id && option.id === el.option?.id
            : el.id === product.id
    })

    if (pr) {
        return <div
            className={classNames(styles.button, styles.buttonCounter, styles.buttonInCart, progress ? styles.progress : '')}>
            <img className={styles.minus} onClick={() => minusProduct(pr)} src={minus} alt=""/>
            <div className={styles.price}>{pr.quantity}</div>
            <img className={styles.plus} onClick={() => plusProduct(pr)} src={plus} alt=""/>
        </div>
    }

    return <div
        className={classNames(styles.buttonInCart, styles.button, disabled ? styles.buttonDisabled : '', progress ? styles.progress : '')}
        onClick={(evt) => addToCart(product, evt.currentTarget)}>
        в корзину
    </div>

}

export const MobileCardButton = ({product, onClick}) => {
    onClick = onClick || undefined
    const {context} = useContext(AppContext)
    const [progress, setProgress] = useState(false)

    const addToCart = (product, element) => {
        if (onClick && product.options.length) {
            onClick()
            return;
        }

        if (progress) return
        setProgress(true)

        context.processor.cart
            .addProduct({id: product.id})
            .then((response) => {
                context.cart.dispatch({type: 'setCart', payload: response.cart});

                window.dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'add': {
                            'products': [
                                {
                                    'id': product.id,
                                    'name' : product.title,
                                    'price': product.prices.actual,
                                    'category': product.category.title,
                                }
                            ]
                        }
                    }
                });
            })
            .finally(() => {
                setProgress(false);
                animateToCart(element, 'beforebegin', true);
            })
            .catch(err => {
                if (err.code === 401) {
                    context.modals.address.dispatch({
                        type: "open",
                        payload: {
                            callback: () => {
                                context.processor.cart
                                    .addProduct({id: product.id})
                                    .then((response) => context.cart.dispatch({
                                        type: 'setCart',
                                        payload: response.cart
                                    }))
                                    .catch(err => context.toast.error(err.message))
                            }
                        }
                    })
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    };

    const pr = Object.values(context.cart.state.products).find(el => el.id === product.id)

    return <div className={styles.mobileLabel}>
        {product?.prices.old ? <div className={classNames(styles.mobilePrice, styles.mobileDiscount)}>
            <div className={styles.mobileDiscountText}>{product?.prices.old}</div>
            <div className={styles.mobilePriceText}> {product?.prices.actual} ₽</div>
        </div> : <div className={styles.mobilePrice}>
            <div className={styles.mobilePriceText}> {product?.prices.actual} ₽</div>
        </div>}
        {pr ?
            <div>
                <img className={styles.mobilePlus} src={plusRed} alt="" onClick={(evt) => addToCart(product, evt.currentTarget)}/>
            </div>
            :
            <div>
                <img className={styles.mobilePlus} src={plusGray} alt="" onClick={(evt) => addToCart(product, evt.currentTarget)}/>
            </div>}

    </div>
}
