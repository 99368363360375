import React, {useContext, useEffect} from "react";
import {generatePath, useHistory} from "react-router-dom";

import {AppContext} from "../../../store/context";
import {STATIC_HOST_PRODUCT} from "../../../const";
import {MobileCardButton, ProductCardButton} from "./Button";

import spice from '../../../static/icons/catalog/spice-card.svg'
import spicyAll from '../../../static/icons/catalog/spicy-all.svg';
import hit from '../../../static/icons/catalog/hit-card.svg'
import discount from '../../../static/icons/catalog/discount.svg'

import styles from './ProductCard.module.css'
import {publicRoutes} from "../../../routing";

export const ProductCard = ({product, category, market}) => {
    market = market || false
    category = category || product.category

    const {context} = useContext(AppContext)
    const history = useHistory()

    const show = (isScrolling = false) => {
        if (isScrolling) {
            context.modals.product.dispatch({type: 'setOpenScrolling', payload: true})
        }

        window.dataLayer.push({
            'ecommerce': {
                'currencyCode': 'RUB',
                'detail': {
                    'products': [
                        {
                            'id': product.id,
                            'name' : product.title,
                            'price': product.prices.actual,
                            'category': product.category.title,
                        }
                    ]
                }
            }
        });

        market
            ? history.push(generatePath(publicRoutes.deliveryCategoryKoreanProducts.path[1], {
                product: product.slug
            }))
            : history.push(generatePath(publicRoutes.deliveryCategory.path[1], {
                slug: category.slug,
                product: product.slug
            }))
    }

    return <div className={styles.card}>
        {product?.prices.old ? <img className={styles.discountIcon} src={discount} alt=""/> : null}
        <div className={styles.image} onClick={() => show()}>
            <img src={STATIC_HOST_PRODUCT + product?.image?.source} alt={product?.image?.alt}/>
        </div>
        <div className={styles.inner}>
            <div className={styles.header}>
                <div className={styles.title} onClick={() => show()}>
                    {product?.title}
                </div>
                <div className={styles.tags}>
                    {product?.tags.find(e => e.slug === 'ikonkaostroe') ?
                        <img width="15" height="24" src={spicyAll} alt=""/> : null}
                    {product?.tags.find(e => e.slug === 'ikonkaostrotaregyliryetsya') ?
                        <img src={spice} alt=""/> : null}
                    {product?.tags.find(e => e.slug === 'ikonkahiti') ?
                        <img src={hit} alt=""/> : null}
                </div>
            </div>
            <div className={styles.body}>
                {product?.content.preview}
            </div>
            <div className={styles.footer}>
                <div className={styles.weight}>{product?.content.weight}</div>
                {context.settings.isMobile
                    ? <MobileCardButton product={product} onClick={() => show(true)}/>
                    : <ProductCardButton product={product} onClick={() => show()}/>}
            </div>
        </div>
    </div>
}

export const ProductCardCustomPage = ({product, onSelect}) => {
    const {context} = useContext(AppContext)

    const show = () => {
        onSelect(product)
    }

    return <div className={styles.card}>
        {product?.prices.old ? <img className={styles.discountIcon} src={discount} alt=""/> : null}
        <div className={styles.image} onClick={() => show()}>
            <img src={STATIC_HOST_PRODUCT + product?.image?.source} alt={product?.image?.alt}/>
        </div>
        <div className={styles.inner}>
            <div className={styles.header}>
                <div className={styles.title} onClick={() => show()}>
                    {product?.title}
                </div>
                <div className={styles.tags}>
                    {product?.tags.find(e => e.slug === 'ikonkaostroe') ?
                        <img width="15" height="24" src={spicyAll} alt=""/> : null}
                    {product?.tags.find(e => e.slug === 'ikonkaostrotaregyliryetsya') ?
                        <img src={spice} alt=""/> : null}
                    {product?.tags.find(e => e.slug === 'ikonkahiti') ?
                        <img src={hit} alt=""/> : null}
                </div>
            </div>
            <div className={styles.body}>
                {product?.content.preview}
            </div>
            <div className={styles.footer}>
                <div className={styles.weight}>{product?.content.weight}</div>
                {context.settings.isMobile
                    ? <MobileCardButton product={product} onClick={() => show()}/>
                    : <ProductCardButton product={product} onClick={() => show()}/>}
            </div>
        </div>
    </div>
}

export const AdditionalProduct = ({product}) => {
    const {context} = useContext(AppContext)

    const show = (product) => {
        context.modals.additionalProduct.dispatch({type: 'open', payload: product})
    }

    return <div className={styles.additionalItem}>
        <div className={styles.additionalItemImage} onClick={() => show(product)}>
            <img src={STATIC_HOST_PRODUCT + product?.image?.source} alt={product?.image?.alt}/>
        </div>
        <div className={styles.additionalItemFooter}>
            <div className={styles.additionalItemTitle}>
                <div className={styles.additionalItemTitleText}>{product?.title}</div>
                {!context.settings.isMobile
                    ? <div className={styles.additionalItemButton}>
                        <ProductCardButton product={product} onClick={() => show(product)} />
                    </div>
                    : <div className={styles.additionalItemWeight}>{product?.content.weight}</div>}
            </div>
            {context.settings.isMobile
                ? <div className={styles.additionalItemButton}>
                    <ProductCardButton product={product} onClick={() => show(product)}/>
                </div>
                : <div className={styles.additionalItemWeight}>{product?.content.weight}</div>
            }
        </div>
    </div>
}
