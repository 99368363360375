import React from "react";
import {usePageViews} from "../analytics";

const EmptyLayout = ({children}) => {
    usePageViews();

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default EmptyLayout;
