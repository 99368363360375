import {useContext, useEffect, useState} from "react";

import {AppContext} from "../../store/context";
import {classNames} from "../../helper";

import styles from "./Wrapper.module.css";

export const ModalWrapper = ({children}) => {
    const {context} = useContext(AppContext)

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    const isOpen =
        context.modals.address.state.open
        || context.modals.cart.state.open
        || context.modals.action.state.open
        || context.modals.personal.state.open
        || context.modals.restaurant.state.open
        || context.modals.nav.state.open
        || context.modals.operating.state.open
        || context.modals.giftCertificate.state.open
        || context.modals.callBack.state.open
        // only on mobile
        || (context.settings.isMobile ? context.modals.product.state.open : false)
        || (context.settings.isMobile ? context.modals.additionalProduct.state.open : false)
        || false

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
            }, 400)
        }
    }, [isOpen])

    const closeModal = (e) => {
        if (e.target.classList.contains(styles.modalOverlay)) {
            context.modals.address.dispatch({type: "close"})
            context.modals.cart.dispatch({type: "close"})
            context.modals.action.dispatch({type: "close"})
            context.modals.personal.dispatch({type: "close"})
            context.modals.restaurant.dispatch({type: "close"})
            context.modals.nav.dispatch({type: "close"})
            context.modals.operating.dispatch({type: "close"})
            context.modals.giftCertificate.dispatch({type: "close"})
            context.modals.callBack.dispatch({type: "close"})
        }
    }

    return <div className={classNames(styles.modalOverlay, (open ? styles.modalOpen : ''))}
                style={{display: display ? "block" : "none"}}
                onClick={(e) => closeModal(e)}>
        {children}
    </div>
}

export const ProductModalWrapper = ({children, isOpen, onDismiss}) => {
    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
            }, 400)
        }
    }, [isOpen])

    const closeModal = (e) => {
        if (e.target.classList.contains(styles.productModalOverlay)) {
            onDismiss()
        }
    }

    return display ? <div className={classNames(styles.productModalOverlay, (open ? styles.modalOpen : ''))}
                          style={{display: display ? "block" : "none"}}
                          onClick={(e) => closeModal(e)}>
        {children}
    </div> : null
}

export const Clearfix = () => {
    return <div className={styles.clearfix}/>
}
