import React, {useState} from 'react'
import {generatePath} from "react-router-dom";

import {AppContext} from "../store/context";
import {LoaderFullsize} from "../components/common/Loader";
import {publicRoutes} from "../routing";
import EmptyLayout from "../layout/EmptyLayout";
import MainLayout from "../layout/MainLayout";
import DeliveryLayout from "../layout/DeliveryLayout";
import {ProductCardCustomPage} from "../components/sections/catalog/ProductCard";

import styles from "./CustomPage.module.css";
import {ResponsiveProductModal} from "../components/modals/Product";
import {classNames} from "../helper";

export class CustomPage extends React.Component {
    constructor() {
        super();

        this.state = {
            page: null,
            products: [],
            loaded: false
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const path = this.props.match.params[0];

        this.context.context.processor.screen.page(path).then(res => {
            this.setState({page: res.page, products: res.products, loaded: true});
        }).catch(err => {
            this.props.history.push(generatePath(publicRoutes.notFound.path))
        })
    }

    componentDidUpdate(prevProps) {
        const path = this.props.match.params[0];
        const prevPath = prevProps.match.params[0];

        if (path !== prevPath) {
            this.setState({page: null, products: [], loaded: false});
            this.componentDidMount()
        }
    }

    render() {
        return this.state.loaded ?
            this.state.page != null
                ? <RenderedPage page={this.state.page} products={this.state.products}/>
                : this.props.history.push(generatePath(publicRoutes.notFound.path))
            : <LoaderFullsize/>
    }
}

const RenderedPage = ({page, products}) => {
    const [selected, setSelected] = useState(null)
    const [open, setOpen] = useState(false)
    switch (page.layout) {
        case 1:
            return <MainLayout>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <div className={styles.titleText}>{page.title}</div>
                    </div>
                    <div className={classNames(styles.inner, styles.innerMain)}>
                        <div dangerouslySetInnerHTML={{__html: page.source}}/>
                    </div>
                </div>
            </MainLayout>
        case 2:
            return <DeliveryLayout>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <div className={styles.titleText}>{page.title}</div>
                    </div>
                    <div className={styles.inner}>
                        <div className={styles.source}
                             dangerouslySetInnerHTML={{__html: page.source}}/>
                        <div className={styles.grid}>
                            {products.map(el => {
                                return <ProductCardCustomPage onSelect={() => {setSelected(el); setOpen(true)}} product={el}/>
                            })}
                        </div>
                        {page.sourceAfter ? <div
                            className={styles.source}
                            dangerouslySetInnerHTML={{__html: page.sourceAfter}}/> : null}
                        <ResponsiveProductModal product={selected}
                                                isOpen={open}
                                                onDismiss={() => setOpen(false)}
                        />
                    </div>
                </div>
            </DeliveryLayout>
        case 3:
            return <EmptyLayout>
                <div dangerouslySetInnerHTML={{__html: page.source}}/>
            </EmptyLayout>
        default:
            return <LoaderFullsize/>
    }
};

CustomPage.contextType = AppContext