import InputMask from "react-input-mask";
import {useState} from "react";
import Calendar from "react-calendar";

import arrowRight from "../../static/icons/cart/arrow-right.svg";
import arrowLeft from "../../static/icons/cart/arrow-left.svg";
import {classNames, unmaskDigits} from "../../helper";

import styles from "./Calendar.module.css";

export const CalendarSelector = ({className, minDate, maxDate, onSuccess, withInterval, isDelivery}) => {
    minDate = minDate || new Date()
    withInterval = withInterval || false

    const [date, setDate] = useState(minDate)
    const [time, setTime] = useState(null)

    const validateTime = () => {
        const timeUnmasked = unmaskDigits(time)
        if (timeUnmasked.length === 4) {
            const today = new Date()
            const dTime = new Date('1970-01-01T' + time);

            date.setHours(dTime.getHours())
            date.setMinutes(dTime.getMinutes())

            if (date.getMonth() === today.getMonth() && date.getDay() === today.getDay()) {
                const minDiff = isDelivery ? 90 : 30;
                const diff = dateDiffInMinutes(today, date)

                if (diff < minDiff) {
                    date.setMinutes(date.getMinutes() + (-diff) + minDiff)

                    setTime(formatTime(date))
                }
            }

            return true
        }

        return false
    }

    const dateDiffInMinutes = (a, b) => {
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes());

        return Math.floor((utc2 - utc1) / (1000 * 60));
    }


    const _calendarInterval = () => {
        const [from] = formatInterval(time, isDelivery)

        return <div className={classNames(styles.calendarInterval, isDelivery ? '' : styles.line)}>
            {isDelivery ?
            <>
                <div className={styles.calendarIntervalLabel}>Привезем к <span className={styles.calendarIntervalValue}>{from}</span></div>
                <div className={styles.calendarIntervalLabel}>(ориентир +-10 минут)</div>
            </>
                :
            <>
                <div className={styles.calendarIntervalLabel}>Заказ будет готов к</div>
                <div className={styles.calendarIntervalValue}>{from}</div>
            </>
            }
        </div>
    }

    return <div className={`${styles.calendar} ${className}`}>
        <div className={styles.calendarSelector}>
            <Calendar
                formatMonthYear={(locale, date) => `${new Intl.DateTimeFormat("ru", {month: "long"}).format(date)} ${date.getFullYear()}`}
                nextLabel={<img src={arrowRight} alt=""/>}
                next2Label={null}
                prevLabel={<img src={arrowLeft} alt=""/>}
                prev2Label={null}
                minDate={minDate}
                maxDate={maxDate}
                value={date}
                onChange={(date) => setDate(date)}
                minDetail={"month"}
                maxDetail={"month"}
            />
        </div>
        <div className={styles.calendarDetail}>
            <div className={styles.calendarDate}>
                <span>Дата: </span>
                <span>{formatDate(date)}</span>
            </div>
            <div className={styles.calendarTime}>
                <div className={styles.calendarTimeLabel}>введите время</div>
                <InputMask
                    formatChars={{
                        '1': '[1-2]',
                        '2': time?.startsWith('2') ? '[0-2]' : '[2-9]',
                        '3': time?.startsWith('22') ? '[0-3]' : '[0-5]',
                        '4': time?.startsWith('22:3') ? '[0]' : '[0-9]'
                    }}
                    onChange={(e) => setTime(e.currentTarget.value)}
                    alwaysShowMask={true}
                    mask={'12:34'}
                    value={time}
                    className={styles.calendarTimeSelector}/>
            </div>
            {validateTime() && withInterval
                ? <_calendarInterval/>
                : null}
            {validateTime()
                ? <div className={styles.calendarButton} onClick={() => onSuccess(date, time)}> Далее</div>
                : null}
        </div>
    </div>
}

export function formatDate(d) {
    return `${new Intl.DateTimeFormat("ru", {
        day: "2-digit",
        month: "long"
    }).format(d)} ${d.getFullYear()}`
}

export function formatTime(d) {
    const leadingZero = (num) => `0${num}`.slice(-2);

    const formatTime = (d) =>
        [d.getHours(), d.getMinutes()]
            .map(leadingZero)
            .join(':');

    return formatTime(d)
}

export function formatInterval(t, isDelivery) {
    const from = new Date('1970-01-01T' + t);
    const to = new Date('1970-01-01T' + t);

    isDelivery
        ? to.setHours(to.getHours() + 1)
        : from.setMinutes(to.getMinutes())

    return [formatTime(from), formatTime(to)]
}
