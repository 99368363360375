import React from "react";
import styles from "./Article.module.css";
import back from "../../static/icons/conditions/back.svg";
import {generatePath} from "react-router-dom";
import {publicRoutes} from "../../routing";
import {AppContext} from "../../store/context";
import {LoaderFullsize} from "../../components/common/Loader";
import {STATIC_HOST_ARTICLE} from "../../const";

export default class Article extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false,
            article: null,
            recommendations: [],
        }
    }

    slugFromProps(props) {
        return props.match.params.hasOwnProperty('slug')
            ? props.match.params.slug
            : null
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        const slug = this.slugFromProps(this.props)
        if (!slug) {
            this.props.history.push(generatePath(publicRoutes.notFound.path))
            return
        }

        this.context.context.processor.screen.article(slug).then(res => this.setState({
            article: res.article,
            loaded: true,
            recommendations: res.recommendations,
        }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.slugFromProps(this.props) !== this.slugFromProps(prevProps)) {
            this.componentDidMount()
        }
    }

    render() {
        return this.state.loaded ? <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleBack} onClick={this.props.history.goBack}>
                    <span><img src={back} alt=""/></span>
                    <span>назад</span>
                </div>
                <h1 className={styles.titleText}>{this.state.article.title}</h1>
            </div>
            <div className={styles.inner}>
                <div className={styles.article}>
                    <div className={styles.image}>
                        <img src={STATIC_HOST_ARTICLE + this.state.article.image?.source} alt={this.state.article.image?.alt}/>
                    </div>
                    <div className={styles.articleText}>
                        <div className={styles.properties}>
                            <div className={styles.createdAt}>{this.state.article.createdAt}</div>
                            <div className={styles.timeToRead}>{this.state.article.timeToRead}</div>
                        </div>
                        <div className={styles.categories}>{this.state.article.categories.map(el => {
                            return <div className={styles.category}>{el.name}</div>
                        })}</div>
                        <div className={styles.text} dangerouslySetInnerHTML={{__html: this.state.article.text}}></div>
                    </div>
                </div>
                {this.state.article.source ?
                    <div className={styles.source} dangerouslySetInnerHTML={{__html: this.state.article.source}}/>
                    : null}
            </div>
            <div className={styles.recommendations}>
                <div className={styles.recommendationTitle}>вам может быть интересно:</div>
                <div className={styles.grid}>
                    {this.state.recommendations.map(el => {
                        return <div className={styles.card}
                                    onClick={() => this.props.history.push(generatePath(publicRoutes.blogArticle.path, {slug: el.slug}))}>
                            <div className={styles.cardImage}>
                                <img src={STATIC_HOST_ARTICLE + el.image?.source} alt={el.image?.alt}/>
                            </div>
                            <div className={styles.cardBody}>
                                <div className={styles.categories}>{el.categories.map(el => {
                                    return <div className={styles.category}>{el.name}</div>
                                })}</div>
                                <div className={styles.cardTitle}>{el.title}</div>
                                <div className={styles.cardPreview}>{el.preview}</div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div> : <LoaderFullsize/>

    }
}
Article.contextType = AppContext
