import {useContext, useRef} from 'react'
import Slider from "react-slick";

import {AppContext} from "../../../store/context";

import arrow_left from "../../../static/icons/arrow_left.svg"
import arrow_right from "../../../static/icons/arrow_right.svg"
import testim from '../../../static/images/testimonials/image.png'

import styles from './Testimonials.module.css'

export const Testimonials = () => {
    const {context} = useContext(AppContext)
    const ref = useRef();

    const settings = {
        slidesToShow: context.settings.isMobile ? 1 : 2,
        speed: 500,
        arrows: false,
        draggable: true,
        variableWidth: true,
        className: styles.slider,
    };

    const next = () => {
        ref.current.slickNext();
    }

    const prev = () => {
        ref.current.slickPrev();
    }

    return <div className={styles.container}>
        <div className={styles.title}>
            <div className={styles.preHead}>피드백</div>
            <h2 className={styles.head}>отзывы наших гостей</h2>
        </div>
        <div className={styles.inner}>
            <Slider ref={c => (ref.current = c)} {...settings}>
                {context.common.state.testimonials.map(el => {
                    return <Testimonial el={el}/>
                })}
            </Slider>
            <div className={styles.icons}>
                <div className={styles.iconLeft} onClick={() => {
                    prev()
                }}>
                    <img src={arrow_left} alt=""/>
                </div>
                <div className={styles.iconRight} onClick={() => {
                    next()
                }}>
                    <img src={arrow_right} alt=""/>
                </div>
            </div>
        </div>
    </div>
}

export const Testimonial = ({el}) => {
    const monthNames = ["январь",
        "февраль", "март", "апрель", "май", "июнь",
        "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"
    ];

    const date = new Date(el.date.date)

    return <div className={styles.testimonial}>
        <div className={styles.testimonialHead}>
            <div className={styles.testimonialTitle}>
                <div className={styles.testimonialName}>{el.title}</div>
                <div className={styles.testimonialDate}>
                    {`${monthNames[date.getMonth()]}, ${date.getFullYear()}`}
                </div>
            </div>
            <div className={styles.testimonialImage}>
                {/*<img src={testim} alt=""/>*/}
            </div>
        </div>
        <div className={styles.testimonialText}>
            {el.text}
        </div>
    </div>
}
