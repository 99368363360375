import {useHistory} from 'react-router-dom'

import styles from './Buttons.module.css'

export const Button = ({className, blank, text, link, onClick}) => {
    const history = useHistory();

    const redirect = (link) => {
        return () => {
            if (blank || link.lastIndexOf("http") === 0) {
                window.open(link, "_blank")
                return
            }
            history.push(link)
        }
    }

    onClick = onClick || redirect(link);

    return <div className={className} onClick={onClick}>
        <span>{text}</span>
    </div>
};

export const WhiteButton = ({text, onClick, link, blank}) => {
    return <Button
        className={styles.button + ' ' + styles.buttonWhite}
        blank={blank}
        text={text}
        onClick={onClick}
        link={link}
    />
};

export const RedButton = ({text, onClick, link, blank, className}) => {
    return <Button
        className={styles.button + ' ' + styles.buttonRed + ' ' + className}
        blank={blank}
        text={text}
        onClick={onClick}
        link={link}
    />
};