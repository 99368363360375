import React, {createRef, useEffect, useState} from "react";
import InputMask from "react-input-mask";

import {AppContext} from "../../store/context";
import {RedButton} from "../../components/common/Buttons";
import {classNames} from "../../helper";
import {Checkbox, Question, QuestionStars, QuestionText, QuestionTextarea} from "../../components/common/Form";

import otzyv from '../../static/images/forms/otzyv-delivery.jpg'

import styles from "./FeedbackDelivery.module.css";


export default class FeedbackDelivery extends React.Component {
    constructor() {
        super();
        this.form = createRef()

        this.state = {
            proceed: false,
            step: 1,

            number: '',

            answers: null,
            overall: 1,
            comment: '',
        }
    }

    prevStep() {
        window.scrollTo({top: this.form.current.offsetTop - 20, left: 0, behavior: "smooth"});

        this.setState({step: this.state.step - 1})
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    send() {
        if (!this.state.number) {
            this.context.context.toast.error('Введите номер заказа или телефон');
            return
        }

        this.context.context.processor.form
            .feedbackDelivery(this.state)
            .then(res => this.setState({proceed: true}))
            .catch(err => this.context.context.toast.error(err.message))
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleText}>обратная связь: доставка</div>
                <div className={styles.titleSub}>Обратная связь помогает нам стать лучше и <br/>сделать посещение более
                    комфортным для Вас
                </div>
            </div>
            <div className={styles.inner}>
                <div className={classNames(styles.form, this.state.proceed ? styles.formProceed : null)}
                     ref={this.form}>
                    <div className={styles.formSteps}>
                        <img src={otzyv} alt=""/>
                    </div>
                    {this.state.proceed ?
                        <div className={styles.typo}>
                            <div className={styles.head}>
                                <span>Спасибо, что помогаете нам улучшать сервис! </span>
                            </div>
                            <div className={styles.text}>
                                Вы также можете стать нашим <span>Тайным Агентом</span> и совместить приятное с
                                полезным:
                                <br/><br/>
                                В качестве благодарности за проверку ресторана Вы получите сертификат на 1000₽,
                                который можно потратить в любом заведении нашей сети.
                            </div>
                            <div className={styles.button}>
                                <RedButton onClick={() => this.props.history.push("/agent")}
                                           text={"стать тайным агентом"}/>
                            </div>
                        </div> : <div className={styles.survey}>
                            <div className={classNames(styles.block, styles.active)}>
                                <div className={styles.questions}>
                                    <QuestionText text={"Номер заказа или телефона, на который был сделан заказ *"}
                                                  value={this.state.number}
                                                  onChange={(e) => this.setState({number: e.currentTarget.value})}/>
                                    <QuestionStars value={this.state.overall}
                                                   max={5}
                                                   onSelect={(val) => this.setState({overall: val})}
                                                   text={"Оцените общее впечатление о посещении"}/>
                                    <AdditionalQuestions value={this.state.overall} onSelect={(items) => {
                                        this.setState({items: items.map(el => el.text).join(';')})
                                    }}/>
                                    <QuestionTextarea
                                        text={"Пожалуйста, оставьте комментарий для улучшения качества доставки"}
                                        value={this.state.comment}
                                        onChange={(e) => this.setState({comment: e.currentTarget.value})}/>
                                </div>
                                <div className={classNames(styles.nextButton, styles.finalButton)}>
                                    <RedButton onClick={() => this.send()} text={"оставить оценку"}/>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    }
}

const AdditionalQuestions = ({value, onSelect}) => {
    const [questions, setQuestions] = useState([])

    const title = value === 5 ? 'Что понравилось?' :
        value === 4 ? 'Над чем работать?' :
            'Что огорчило?';

    useEffect(() => {
        const mode = value === 5 ? 'good' :
            value === 4 ? 'normal' :
                'bad';

        onSelect([])
        setQuestions(Object.values(q[mode]))
    }, [value])

    const q = {
        bad: {
            1: {text: 'Скорость доставки', checked: false},
            2: {text: 'Температура блюд', checked: false},
            3: {text: 'Качество упаковки', checked: false},
            4: {text: 'Качество блюд', checked: false},
            5: {text: 'Привезли не весь заказ', checked: false},
            6: {text: 'Опоздали с доставкой', checked: false},
            7: {text: 'Коммуникация с курьером', checked: false},
        },
        normal: {
            1: {text: 'Качество блюд', checked: false},
            2: {text: 'Качество упаковки', checked: false},
            3: {text: 'Опоздали с доставкой', checked: false},
            4: {text: 'Коммуникация с курьером', checked: false},
            5: {text: 'Температура блюд', checked: false},
            6: {text: 'Скорость доставки', checked: false},
            7: {text: 'Недовес блюд', checked: false},
            8: {text: 'Привезли не весь заказ', checked: false},
        },
        good: {
            1: {text: 'Вкусные блюда', checked: false},
            2: {text: 'Блюда доставили горячими', checked: false},
            3: {text: 'Скорость доставки', checked: false},
            4: {text: 'Качественная упаковка ', checked: false},
            5: {text: 'Вежливый курьер', checked: false},
            6: {text: 'Вежливый оператор', checked: false},
        },
    }

    const toggle = (question) => {
        const index = questions.indexOf(question)
        const current = questions

        current[index].checked = !current[index].checked

        onSelect(current.filter(el => el.checked))
        setQuestions(_ => [...current])
    }

    return <div className={styles.additionalQuestions}>
        <div className={styles.additionalQuestionsTitle}>{title}</div>
        <div className={styles.additionalQuestionsCheckboxes}>
            {questions.map(el =>
                <Checkbox checked={el.checked === true} text={el.text} onClick={() => toggle(el)}/>
            )}
        </div>
    </div>
}

FeedbackDelivery.contextType = AppContext;
