import React from "react";

import {Loader} from "../components/common/Loader";
import {AppContext} from "../store/context";
import {ProductCard} from "../components/sections/catalog/ProductCard";

import styles from "./Search.module.css";
import back from "../static/icons/conditions/back.svg";

export default class Search extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loaded: false,
            results: [],
        }
    }

    queryFromProps(props) {
        if (props.location.search) {
            const params = new URLSearchParams(props.location.search);
            return params.get('query');
        }

        return ""
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        const query = this.queryFromProps(this.props)
        if (!query) {
            this.setState({loaded: true, results: []})
            return
        }

        this.context.context.processor.catalog
            .search(query)
            .then(res => this.setState({loaded: true, results: res.products,}))
            .catch(err => {
                this.setState({loaded: true, results: []})
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({loaded: false, results: []})
            this.componentDidMount()
        }
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleBack} onClick={this.props.history.goBack}>
                    <span><img src={back} alt=""/></span>
                    <span>назад</span>
                </div>
                <div className={styles.titleText}>{`Результаты поиска «${this.queryFromProps(this.props)}»`}</div>
            </div>
            <div className={styles.inner}>
                {this.state.loaded ? <div className={styles.grid}>
                    {this.state.results.map(el => {
                        return <ProductCard product={el}/>
                    })}
                </div> : <div className={styles.loader}>
                    <Loader/>
                </div>}
            </div>
        </div>
    }
}

Search.contextType = AppContext