import React from 'react'
import styles from "./Menu.module.css";
import {RedButton, WhiteButton} from "../components/common/Buttons";
import {AppContext} from "../store/context";
import {generatePath} from "react-router-dom";
import {publicRoutes} from "../routing";
import back from "../static/icons/conditions/back.svg";

export default class Menu extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    render() {
        return <>
            <div className={styles.container}>
                <div className={styles.title}>
                    <div className={styles.titleBack} onClick={this.props.history.goBack}>
                        <span><img src={back} alt=""/></span>
                        <span>назад</span>
                    </div>
                    <div className={styles.titleText}>Меню ресторана</div>
                </div>
                <div className={styles.inner}>
                    <p className={styles.text}>
                        В стенах ресторанов «Кореана» подают не просто еду. Для нас важно дать гостям нечто большее –
                        яркие впечатления и новый эмоциональный опыт.
                    </p>
                    <div className={styles.buttonWrapper}>
                        <WhiteButton
                            link={'/koreana-menu'}
                            target={'_blank'}
                            text={"Основное"}
                        />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <WhiteButton
                            link={'/koreana-barmenu'}
                            target={'_blank'}
                            text={"Барное"}
                        />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <WhiteButton
                            link={'/koreana-grillmenu'}
                            target={'_blank'}
                            text={"Гриль"}
                        />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <WhiteButton
                            link={'/koreana-childrensmenu'}
                            target={'_blank'}
                            text={"Детское"}
                        />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <RedButton text={"Забронировать стол"}
                                   onClick={() => this.context.context.modals.restaurant.dispatch(
                                       {type: "show", payload: this.context.context.common.state.restaurants[0]}
                                   )}
                        />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <RedButton text={"подарочный сертификат"}
                                   onClick={() => this.context.context.modals.giftCertificate.dispatch({type: 'open'})}
                        />
                    </div>
                </div>
            </div>
        </>
    }
}

Menu.contextType = AppContext;
