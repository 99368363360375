import React, {useContext, useEffect, useRef, useState} from 'react'

import arrow from '../../../../static/icons/arrow_right_hits.svg'
import arrowDown from '../../../../static/icons/arrow_down_hits.svg'

import styles from './Kimchi.module.css'
import {AppContext} from "../../../../store/context";
import {RedButton} from "../../../common/Buttons";

export const Kimchi = () => {
    const {context} = useContext(AppContext)

    const [items, setItems] = useState(context.common.state.kimchi)
    const [selected, setSelected] = useState(context.common.state.kimchi[0])
    const [pos, setPos] = useState(0);
    const ref = useRef()

    useEffect(() => {
        setSelected(items[0])
        setItems(items.slice(1))
    }, [])

    const changeSlide = (el) => {
        const it = items
        const i = it.findIndex((e) => e.id === el.id)

        it[i] = selected

        setItems(it)
        setSelected(el)
    }

    const scroll = () => {
        const step = 180 + 13
        const maxPos = step * 4

        const newPos = pos + step

        setPos(newPos < maxPos ? newPos : 0)

        ref?.current?.scrollTo({
            top: newPos < maxPos ? newPos : 0,
            behavior: "smooth"
        })
    }

    return <div className={styles.container}>
        <div className={styles.title}>
            <h2 className={styles.head}>10 ЛЕТ - <br /> 10 ВИДОВ КИМЧИ</h2>
            <div className={styles.subtitleWrapper}>
                <span className={styles.subtitle}>우리국보</span>
            </div>
        </div>
        <p className={styles.description}>
            В нашем первом ресторане на Гороховой 17/56 <br />
            вас ждет уникальное событие. В честь 10-ти летия <br />
            компании мы приготовили для вас <br />
            <span className={styles.textRed}>10 видов кимчи!</span>
        </p>
        <div className={styles.inner}>
            <div className={styles.preview}>
                <div className={styles.previewImage}>
                    <img src={selected?.img}
                         alt={selected?.title}/>
                </div>
                <div className={styles.previewTextHolder}>
                    <div className={styles.previewTitle}>
                        <div className={styles.previewTitleBullet}>{selected?.suptitle}</div>
                        <div className={styles.previewTitleText}>{selected?.title}</div>
                    </div>
                    <div className={styles.previewText}>
                        {selected?.description}
                    </div>
                </div>
            </div>
            <div className={styles.nextButton}>
                <img src={arrow} alt=""/>
            </div>
            <div className={styles.gallery}>
                <div className={styles.galleryInner} ref={ref}>
                    {items.map(el => {
                        return <div className={styles.galleryImage} onClick={() => changeSlide(el)}>
                            <img src={el.img} alt={el.title}/>
                        </div>
                    })}
                </div>
                {!context.settings.isMobile
                 ? <div className={styles.orderTableButtonWrapper}>
                        <RedButton text={"Забронировать стол"} onClick={() => {
                            context.modals.restaurant.dispatch({
                                type: "show",
                                payload: context.common.state.restaurants[4]
                            })
                        }}/>
                    </div>
                 : null}
                {context.settings.isMobile
                    ? <div onClick={() => scroll()} className={styles.galleryScroll}>
                        <img src={arrowDown} alt=""/>
                    </div>
                    : null}
            </div>
        </div>
        {context.settings.isMobile
            ? <div className={styles.orderTableButtonWrapper}>
                <RedButton text={"Забронировать стол"} onClick={() => {
                    context.modals.restaurant.dispatch({
                        type: "show",
                        payload: context.common.state.restaurants[4]
                    })
                }}/>
            </div>
            : null}
    </div>
}
