import styles from './Loader.module.css'
import {classNames} from "../../helper";

export const Loader = ({className}) => {
    return <div className={classNames(styles.loader, className||'')}>
        <div className={styles.ldsRing}>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    </div>
};

export const LoaderSmallWhite = () => {
    return <div className={classNames(styles.loaderSmall)}>
        <div className={classNames(styles.ldsRingWhite)}>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    </div>
}

export const LoaderFullsize = () => {
    return <div className={classNames(styles.loader, styles.loaderFullsize)}>
        <div className={styles.ldsRing}>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    </div>
};